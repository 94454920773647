<div class="commitments-intake-page">
    <div class="heading-container fdl-2">
        <div class="back-action-container">
            <h1><b>Commitment Intake</b></h1>
        </div>
        <div>
            <button type="button" class="btn fdl-btn btn-primary-orange mt-1 mr-3" [ngClass]="{'myButton':(blueThemeMode)}"
                *ngIf="canCreateOneView()" (click)="createClientView()"><span
                    class="material-icons">add</span>
                Create OneView</button>

            <button type="button" class="btn fdl-btn btn-primary-orange mt-1" [ngClass]="{'myButton':(blueThemeMode)}"
                *ngIf="canCreateNewCommitmentIntake()" (click)="openSubmitNewCommitmentsIntake()"><span
                    class="material-icons">add</span>
                Submit New Commitments Intake</button>
        </div>
    </div>
    <div class="form-container">
        <div *ngIf="alertInput.message !== ''">
            <!-- Error Msg Alert -->
            <div class="alert alert-dismissible alert-danger" *ngIf="alertInput.alertType === alertType.Error">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">×</button>
                <i class="material-icons">warning</i>
                <p [innerHtml]="alertInput.message"></p>
            </div>
            <!-- Success Msg Alert -->
            <div class="alert alert-success alert-dismissible" *ngIf="alertInput.alertType === alertType.Success">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">×</button>
                <i class="material-icons" aria-hidden="true">check_circle</i>
                <p [innerHtml]="alertInput.message"></p>
            </div>
        </div>
        <app-commitments-intake-search
            [searchFields]="isLoadSearchFieldSuggestionsCompleted ? searchByFormFieldJson : null"
            (search)="onSearchClicked($event)"></app-commitments-intake-search>
        <div class="commitments-tabs-wrapper" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
            <ul *ngIf="showStaticTab === false" class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item" role="presentation"><a id="AllTab" class="nav-link active show" role="tab"
                        aria-selected="true" href="#tab1" data-toggle="tab" (click)="onTabClicked('All')">All</a></li>
                <li class="nav-item" role="presentation"><a id="TrackedTab" class="nav-link" href="#tab1" role="tab"
                        aria-selected="false" data-toggle="tab" (click)="onTabClicked('Tracked')">Tracked</a></li>
                <li class="nav-item" role="presentation" *ngFor="let item of tabList">
                    <a id="{{item}}Tab" class="nav-link" href="#tab1" data-toggle="tab" role="tab" aria-selected="false"
                        (click)="onTabClicked(item)">{{item}}</a>
                </li>
                <li *ngIf="isCCTAdmin === true" class="nav-item" role="presentation"><a id="PresaleTab" class="nav-link"
                        role="tab" aria-selected="false" href="#tab1" data-toggle="tab"
                        (click)="onTabClicked('Presale')">Presale</a></li>
                <!-- <li class="nav-item"><a class="nav-link" role="tab" aria-selected="false" href="#tab1" data-toggle="tab"
                    (click)="onTabClicked('Internal')">Internal</a></li> -->
            </ul>

            <ul *ngIf="showStaticTab === true" class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item" role="presentation"><a id="AllTab" class="nav-link active show" href="#tab1"
                        data-toggle="tab" role="tab" aria-selected="true" (click)="onTabClicked('All')">All</a></li>
                <li class="nav-item" role="presentation"><a id="TrackedTab" class="nav-link" href="#tab1"
                        data-toggle="tab" role="tab" aria-selected="false" (click)="onTabClicked('Tracked')">Tracked</a>
                </li>
                <li class="nav-item" role="presentation"><a id="CimsTab" class="nav-link" href="#tab1" data-toggle="tab"
                        role="tab" aria-selected="false" (click)="onTabClicked('CIMS')">CIMS</a></li>
                <li class="nav-item" role="presentation"><a id="ClarityTab" class="nav-link" href="#tab1"
                        data-toggle="tab" role="tab" aria-selected="false" (click)="onTabClicked('Clarity')">Clarity</a>
                </li>
                <li class="nav-item" role="presentation"><a id="ManualTab" class="nav-link" href="#tab1"
                        data-toggle="tab" role="tab" aria-selected="false" (click)="onTabClicked('Manual')">Manual</a>
                </li>
                <li class="nav-item" role="presentation"><a id="ServicenowTab" class="nav-link" href="#tab1"
                        data-toggle="tab" role="tab" aria-selected="false"
                        (click)="onTabClicked('Servicenow')">Servicenow</a></li>
                <li class="nav-item" role="presentation"><a id="eCRMTab" class="nav-link" href="#tab1" data-toggle="tab"
                        role="tab" aria-selected="false" (click)="onTabClicked('eCRM')">eCRM</a></li>
                <li *ngIf="isCCTAdmin === true" class="nav-item" role="presentation"><a id="PresaleTab" class="nav-link"
                        role="tab" aria-selected="false" href="#tab1" data-toggle="tab"
                        (click)="onTabClicked('Presale')">Presale</a></li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                    <div class="data-table-container">
                        <app-commitments-intake-list [data]="pageResponseList"
                            [pageTemplateNameTitle]="pageTemplateNameTitle" [queryParam]="queryParam"
                            [pagerObject]="pagerObject" [customFormFieldListJson]="customFormFieldListJson"
                            [pageTemplateData]="pageTemplate" [listFormFieldJson]="listFormFieldJson"
                            [filterColumn]="filterColumn" [isAsc]="isAsc" (search)="searchFromList($event)"
                            (alertChanged)="onAlertChanged($event)" [selectedTabName]="selectedTabName"
                            [isReset]="isReset" (oneViewClicked)="oneViewClicked($event)"></app-commitments-intake-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-new-commitments-intake-drawer *ngIf="showSubmitNewCommitmentsIntakeDrawer" [isEntityLink]="isEntityLink"
    [isDynamicApprover]="isDynamicApprover" [pageTemplateNameTitle]="pageTemplateNameTitle"
    [organizationDetails]="organizationDetails"
    (cancel)="closeSubmitNewCommitmentsDrawers()"></app-new-commitments-intake-drawer>


<app-client-view
    [isCreate]="isCreateNewOneView"
    [isEdit]="isEditOneView"
    [viewId]="selectedOneViewId"
    [canEdit]="canEditOneView"
    (onSuccess)="onOneViewSuccess($event)"></app-client-view>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OneViewNoteInput } from '../self-service/components/commitment-dashboard/models/client-note-input';

@Injectable({
  providedIn: 'root',
})
export class ClientOneViewService {
  private apiUrl = 'oneviewgroup';

  constructor(private http: HttpClient) {}

  get(id: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/' + id);
  }

  getList(queryParams, refresh = false) {
    return this.getApiWithParams(refresh, this.apiUrl, queryParams);
  }
  
  create(data: any): Observable<any> {
    return this.http.post(this.apiUrl, data);
  }

  update(id: string, data: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/${id}`, data);
  }

  bulkUpdate(data: Array<any>): Observable<any> {
    return this.http.put(`${this.apiUrl}/bulkUpdate`, data);
  }

  delete(id): Observable<any> {
    return this.http.delete(this.apiUrl + '/' + id);
  }

  getByClientId(clientId: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/clients/' + clientId);
  }

  public getOneViewNote(id: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this.apiUrl}/${id}/note`, { headers: options, observe: 'response' });
  }

  public updateOneViewNote(noteInput: OneViewNoteInput) {
    const options = { 'refresh': 'true' };

    return this.http.post(`${this.apiUrl}/${noteInput.oneViewId}/note`, noteInput, { headers: options, observe: 'response' });
  }

  private getApiWithParams(refresh = false, url: string, queryParams: any): any {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: refresh ? 'true' : 'false',
      }),
    }; 

    if (queryParams) {
      for (let key of Object.keys(queryParams)) {
        let value = queryParams[key];
        params = params.append(key, value);
      }
    }

    return this.http.get(url, {
      headers: httpOptions.headers,
      params: params,
      observe: 'response',
    });
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IQuery } from "../shared/query";
import { FileUploadHeader } from "../core/constants";
import { IPageTemplateResponse } from "../shared/page-template";
import { CustomHttpParamEncoder } from "../shared/custom-encoder";
import { InterceptorSkipHeader } from 'src/app/core/constants';
import { ISuggestions } from "../shared/suggestion";

@Injectable({
  providedIn: 'root'
})
export class PageTemplateService {
  private pagetemplateAPIURL = 'pagetemplate';
  private selfservicefeatureAPIURL = 'selfservicefeature';
  private pagetemplateResponseAPIURL = 'pagetemplateresponse';
  private pagetemplateMultipleResponseAPIURL = 'pagetemplateresponse/CreatePageTemplateResponseForMultipleResponses';

  private _isResponseCreated: string = "";
  private _isPrePopulateRequest: boolean = false;
  private _sideNav: boolean = false;
  private _isNavigate: boolean = false;
  private _isEntity: boolean = false;
  private _pageTemplateId: number;
  private _formVal: IPageTemplateResponse;

  constructor(private http: HttpClient) { }

  set isNavigate(value) {
    this._isNavigate = value;
  }

  get isNavigate() {
    return this._isNavigate;
  }

  set isEntity(value) {
    this._isEntity = value;
  }

  get isEntity() {
    return this._isEntity;
  }
  set sideNav(value) {
    this._sideNav = value;
  }

  get sideNav() {
    return this._sideNav;
  }

  set isResponseCreated(value) {
    this._isResponseCreated = value;
  }

  get isResponseCreated() {
    return this._isResponseCreated;
  }

  set isPrePopulateRequest(value) {
    this._isPrePopulateRequest = value;
  }

  get isPrePopulateRequest() {
    return this._isPrePopulateRequest;
  }

  set pageTemplateId(value) {
    this._pageTemplateId = value;
  }

  get pageTemplateId() {
    return this._pageTemplateId;
  }

  get formVal(): IPageTemplateResponse {
    return this._formVal;
  }

  set formVal(value: IPageTemplateResponse) {
    this._formVal = value;
  }

  getPageTemplateById(id: number) {
    return this.http.get(this.pagetemplateAPIURL + "/getbyid/" + id);
  }

  getPageTemplateByName(title: string) {
    return this.http.get(this.pagetemplateAPIURL + "/title/" + title);
  }

  getPageTemplateList(queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    let options = { 'refresh': 'true' }
    return this.http.get(this.pagetemplateAPIURL, { headers: options, params: params, observe: 'response' });
  }

  getPageTemplateOtherRequestsList() {
    let options = { 'refresh': 'true' }
    return this.http.get(this.pagetemplateAPIURL + "/otherRequests", { headers: options, observe: 'response' });
  }

  getPageTemplateUniqueRequestsList() {
    let options = { 'refresh': 'true' }
    return this.http.get(this.pagetemplateAPIURL + "/uniqueRequests", { headers: options, observe: 'response' });
  }

  getPageTemplateResponseById(id: number) {
    return this.http.get(this.pagetemplateResponseAPIURL + "/" + id);
  }

  getPageTemplateResponseList(id: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    const options = { 'refresh': 'true' };

    return this.http.get(this.pagetemplateResponseAPIURL + "/responses/" + id, { headers: options, params: params, observe: 'response' });
  }

  getPageTemplateRefreshResponseList(id: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    let options = { 'refresh': 'true' }
    return this.http.get(this.pagetemplateResponseAPIURL + "/responses/" + id, { headers: options, params: params, observe: 'response' });
  }

  exportPageTemplateResponseList(id: number, queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }

    let options = { 'refresh': 'true' }
    return this.http.get(this.pagetemplateResponseAPIURL + "/pagetemplate/" + id + "/exportResponses/", { headers: options, params: params, observe: 'response' });
  }

  deletePageResponse(Id: number) {
    return this.http.delete(`${this.pagetemplateResponseAPIURL}/${Id}`);
  }

  public getCommitmentReviewsByClientId(clientId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this.pagetemplateResponseAPIURL}/clientdashboard/commitments/${clientId}/commitmentreview`, { headers: options, observe: 'response' });
  }

  createPageResponse(responseData) {
    return this.http.post<any>(`${this.pagetemplateResponseAPIURL}`, responseData);
  }
  createMultiplePageResponse(responseData) {
    return this.http.put<any>(`${this.pagetemplateMultipleResponseAPIURL}`, responseData);
  }

  updatePageResponse(responseData) {
    return this.http.put<any>(this.pagetemplateResponseAPIURL + "/" + responseData.id, responseData);
  }

  delegateResponse(id: number, data) {
    return this.http.put<any>(this.pagetemplateResponseAPIURL + "/" + id + "/delegate", data);
  }

  changeApprover(id: number, data) {
    return this.http.put<any>(this.pagetemplateResponseAPIURL + "/" + id + "/changeApprover", data);
  }

  sentBackToApprover(id: number, responseData) {
    return this.http.put<any>(this.pagetemplateResponseAPIURL + "/" + id + "/sendbacktoapprover", responseData);
  }

  getFormFieldSuggestion(pageTemplateId: number, apiKey: string) {
    let options = { 'refresh': 'true' }
    return this.http.get(this.pagetemplateResponseAPIURL + "/suggestions/" + pageTemplateId + "?apiKey=" + apiKey, { headers: options });
  }

  getclientdashboardSuggestion(clientId: number, apiKey: any, queryParams: IQuery = Object.assign({}, {})) {
    let options = { 'refresh': 'true' };
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      if (key.toLocaleLowerCase() === "formfields") {
        const formValues: any[] = JSON.parse(queryParams[key]);
        if (formValues) {
          formValues.forEach(f => {
            switch (f.apiKey) {
              case "product":
                f.apiKey = "productArray";
                break;
              case "bu":
                f.apiKey = "buArray";
                break;
              case "lob":
                f.apiKey = "lobArray";
                break;
            }
          });

          const value = JSON.stringify(formValues);
          params = params.append(key, value);
        }
      }
      else {
        const value = queryParams[key];
        params = params.append(key, value);
      }

    }
    return this.http.get(`${this.pagetemplateResponseAPIURL}/clientdashboard/suggestions/${clientId}?suggestionFields=${encodeURIComponent(JSON.stringify(apiKey))}`, { headers: options, params: params });
  }

  exportClientDashboardPDF(clientId: number, params: any) {
    const exportToPdfUrl = `${this.pagetemplateResponseAPIURL}/clientdashboard/${clientId}/pdf`;
    return this.http.post<any>(exportToPdfUrl, params,
      {
        observe: 'response', responseType: 'blob' as 'json'
      });
  }

  exportClientDashboardPPT(clientId: number, params: any) {
    const exportToPdfUrl = `${this.pagetemplateResponseAPIURL}/clientdashboard/${clientId}/ppt`;
    return this.http.post<any>(exportToPdfUrl, params,
      {
        observe: 'response', responseType: 'blob' as 'json'
      });
  }

  getClientSuggestionsRm(queryParams: IQuery = Object.assign({}, {})) {
    let options = { 'refresh': 'true' }
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    let suggestionAPIURL = this.pagetemplateResponseAPIURL + '/clientdashboard/getrmclients';
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options, params: params });
  }
  getClientSuggestionsAdmin(queryParams: IQuery = Object.assign({}, {})) {
    const options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    const suggestionAPIURL = `${this.pagetemplateResponseAPIURL}/clientdashboard/gettaggedclients`;
    return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options, params: params });
  }

  getCommitmentsbyProduct(clientId: number, queryParams: any) {
    let options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.pagetemplateResponseAPIURL + "/clientdashboard/commitmentsbyproduct/" + clientId, { headers: options, params: params });
  }
  getclientdashboardSummary(clientId: number, queryParams: any) {
    let options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.pagetemplateResponseAPIURL + "/clientdashboard/summary/" + clientId, { headers: options, params: params });
  }
  getclientdashboardCommitments(clientId: number, queryParams: any) {
    let options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.pagetemplateResponseAPIURL + "/clientdashboard/commitments/" + clientId, { headers: options, params: params, observe: 'response' });
  }

  public updateReviewForAllCommitmentsByClientId(clientId: number, reviewComment: string) {
    const temp = {
      'reviewComment': reviewComment
    };
    return this.http.put(this.pagetemplateResponseAPIURL + '/clientdashboard/commitments/' + clientId + '/reviews', temp);
  }

  exportClientdashboardCommitments(clientId: number, queryParams: any) {
    let options = { 'refresh': 'true' }

    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.pagetemplateResponseAPIURL + "/clientdashboard/exportcommitments/" + clientId, { headers: options, params: params, observe: 'response' });


  }

  updateCommitmentRisk(responseId: number, Data) {
    return this.http.put<any>(this.pagetemplateResponseAPIURL + "/clientdashboard/updatecommitmentrisk/" + responseId, Data)
  }
  commitmentCalander(clientId: number, queryParams) {
    let options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';

    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get<any>(this.pagetemplateResponseAPIURL + "/clientdashboard/commitmentscalendardata/" + clientId, { headers: options, params: params })
  }
  getFormFieldSuggestionByUser(pageTemplateId: number) {
    return this.http.get(this.pagetemplateResponseAPIURL + "/suggestionsbyuser/" + pageTemplateId);
  }

  bulkRequest(pageTemplateId, formData) {
    let httpOptions = {
      headers: new HttpHeaders({
        [FileUploadHeader]: ""
      })
    };

    const fd = new FormData();
    for (let key of Object.keys(formData)) {
      let value = formData[key];
      fd.append(key, value);
    }

    return this.http.post(this.pagetemplateResponseAPIURL + '/' + pageTemplateId + '/prepopulaterequest', fd, httpOptions);
  }

  updatePageResponseApprover(responseId: number, responseData) {
    return this.http.put<any>(this.pagetemplateResponseAPIURL + "/" + responseId + "/updateapprover", responseData)
  }

  getSelfServiceFeatureList() {
    let options = { 'refresh': 'true' }
    return this.http.get(this.selfservicefeatureAPIURL, { headers: options, observe: 'response' });
  }

  getCall(url) {
    return this.http.get<any>(url);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { ClientsService } from 'src/app/services/clients.service';
import { IPageTemplate } from 'src/app/shared/page-template';
import { ISuggestions } from 'src/app/shared/suggestion';
import * as $ from 'jquery';


@Component({
  selector: 'app-commitment-dashboard-edit-assignusers',
  templateUrl: './commitment-dashboard-edit-assignusers.component.html',
  styleUrls: ['./commitment-dashboard-edit-assignusers.component.css']
})
export class CommitmentDashboardEditAssignUsersComponent implements OnInit {
 
  @Input() commitmentDetails: any;
  @Input() oneViewId: any;
  @Input() isOneViewSelected: boolean;
  @Output() private closeModal = new EventEmitter();
  @Output() private submitModal = new EventEmitter();
  clientExecutiveSuggestions: any[];
  SelectedCEUsers: any[];
  SelectedCEUsersDetails: any;
  getCEUsersDetails: any;
  isError: boolean = false;
  isAdmin = false;
  errorMsg = '';
  successMsg = '';
  isLoading = false;
  submitClicked: boolean = false;
  disableSaveButton: boolean = true;

  constructor(
    private clientsService: ClientsService,
    private clientCommitmentService: ClientCommitmentsService) { }


  ngOnInit() {
    debugger;
    this.getUserData();
    if (this.oneViewId != undefined && this.oneViewId > 0) {
      this.getExistingOneViewData(this.commitmentDetails.responseId);
    }
    else {
      this.getExistingData(this.commitmentDetails.responseId);
    }
  }
    
  onSelectCEUsers() {
    this.getCEUsersDetails = [];
    this.SelectedCEUsersDetails = [];
    for (var i = 0; i < this.SelectedCEUsers.length; i++) {
      var userData = this.clientExecutiveSuggestions.find(u => u.id == this.SelectedCEUsers[i]);
      this.SelectedCEUsersDetails.push(userData);
    }
    if (this.SelectedCEUsersDetails && this.SelectedCEUsersDetails.length > 0) {
      this.disableSaveButton = false;
    }
    else if (this.SelectedCEUsersDetails && this.SelectedCEUsersDetails.length === 0) {
      this.disableSaveButton = true;
    }
  }

  getUserData() {
    let clientId = this.commitmentDetails.clientId;
    let oneViewGroupId = this.oneViewId;

    if (this.oneViewId > 0) {
      this.clientsService.getClientExecutiveUsersByOneViewGroupId(oneViewGroupId)
        .subscribe(suggestions => {
          this.clientExecutiveSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientExecutiveSuggestions);
        },
          error => { this.errorMsg = error; });
    }
    else {
      this.clientsService.getClientExecutiveUsersByClientId(clientId)
        .subscribe(suggestions => {
          this.clientExecutiveSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientExecutiveSuggestions);
        },
          error => { this.errorMsg = error; });
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  getExistingData(commitmentId: number) {
    this.clientCommitmentService.GetStrategicCLientUsers(commitmentId).subscribe(
      result => {
        this.getCEUsersDetails = result;
        let SelectedCEUsersArray = [];
        for (var i = 0; i < this.getCEUsersDetails.length; i++) {
          SelectedCEUsersArray.push(this.getCEUsersDetails[i].userId);
        }
        this.SelectedCEUsers = SelectedCEUsersArray;
      }

      , error => { }
    );
  }

  getExistingOneViewData(commitmentId: number) {
    this.clientCommitmentService.GetStrategicOneViewUsers(commitmentId).subscribe(
      result => {
        this.getCEUsersDetails = result;
        let SelectedCEUsersArray = [];
        for (var i = 0; i < this.getCEUsersDetails.length; i++) {
          SelectedCEUsersArray.push(this.getCEUsersDetails[i].userId);
        }
        this.SelectedCEUsers = SelectedCEUsersArray;
      }

      , error => { }
    );
  }

  onSaveClick() {
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
    this.submitClicked = true;
    
    if (this.oneViewId > 0) {
      this.updateStrategicOneViewUsers(this.oneViewId);
    }
    else {
      this.updateStrategicClientUsers();
    }
  }

  onCancel() {
    this.closeModal.emit();
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  }

  updateStrategicOneViewUsers(oneViewGroupId: number) {
    var clientId = this.commitmentDetails.clientId;
    var CommitmentId = this.commitmentDetails.responseId;
    var ProjectId = this.commitmentDetails.clarityProjectNumber;
    var request = [];
    if (this.SelectedCEUsers != undefined && this.SelectedCEUsers.length > 0) {
      for (var i = 0; i < this.SelectedCEUsers.length; i++) {
        var tempreq = {
          ClientId: clientId,
          CommitmentId: CommitmentId,
          oneViewGroupId: oneViewGroupId,
          ProjectNumber: ProjectId,
          Userid: this.SelectedCEUsers[i]
        };
        request.push(tempreq);
      }
    }
    this.clientCommitmentService.createStrategicOneViewUsers(CommitmentId, request).subscribe((response) => {
      this.submitModal.emit();

    }, (error) => { });
  }

  updateStrategicClientUsers() {
    debugger;
    var clientId = this.commitmentDetails.clientId;
    var CommitmentId = this.commitmentDetails.responseId;
    var ProjectId = this.commitmentDetails.clarityProjectNumber;
    var request = [];
    if (this.SelectedCEUsers != undefined && this.SelectedCEUsers.length > 0) {
      for (var i = 0; i < this.SelectedCEUsers.length; i++) {
        var tempreq = {
          ClientId: clientId,
          CommitmentId: CommitmentId,
          ProjectNumber: ProjectId,
          Userid: this.SelectedCEUsers[i]
        };
        request.push(tempreq);
      }
    }
    this.clientCommitmentService.createStrategicCLientUsers(CommitmentId, request).subscribe((response) => {
      this.submitModal.emit();
      
    }, (error) => { });
  }

}

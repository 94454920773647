import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/internal/operators/take';
import * as $ from 'jquery';
import { ICreateForm } from 'src/app/admin/manageForms/models/createForm';
import { ManageFormsService } from 'src/app/admin/manageForms/services/manageForms.service';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { IPageTemplate, IPageTemplateResponse } from 'src/app/shared/page-template';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { environment } from 'src/environments/environment';
import { ClientCommitmentDTO } from '../models/client-commitment-d-t-o';
import { UserService } from 'src/app/services/user.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { forkJoin } from 'rxjs';
import { PreferenceService } from 'src/app/services/preference.service';
import { ContentService } from 'src/app/services/content.service';
import { AlertInput } from '../models/alert-input';
import { AlertType } from '../enums/alert-type.enum';
import { CommitmentMapType } from '../enums/commitment-map-type.enum';
import { PageTemplateResponseDetailsDTO } from '../models/page-template-response-details-d-t-o';
import { IUser } from 'src/app/shared/user';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { stripGeneratedFileSuffix } from '@angular/compiler/src/aot/util';

@Component({
  selector: 'app-commitments-intake-list',
  templateUrl: './commitments-intake-list.component.html',
  styleUrls: ['./commitments-intake-list.component.css'],
})
export class CommitmentsIntakeListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data: Array<ClientCommitmentDTO> = [];
  @Input() public queryParam: IQuery;
  @Input() public pagerObject: IPager;
  @Input() public customFormFieldListJson: any[];
  @Input() listFormFieldJson: string;
  @Input() public pageTemplateNameTitle = '';
  @Input() public filterColumn = '';
  @Input() public isAsc = true;
  @Input() public selectedTabName = "All";
  @Input() set pageTemplateData(value: IPageTemplate) {
    this.pageTemplate = value;
    this.isPageTemplateAdmin = this.permission.isPageTemplateAdmin(this.pageTemplate);
    this.isCCTAdminReadOnly = this.permission.isCCTAdminReadOnly(this.pageTemplate);
    this.hasMapOrUnmapPermission = this.isPageTemplateAdmin || (this.isCCTAdmin && this.isPageTemplateAdmin) || this.permission.canMapOrUnmapCommitment();
    if (!this.displayColumns) {
      this.callPreferenceGetService();
    }
  }

  @Output() private search = new EventEmitter<IQuery>();
  @Output() private alertChanged = new EventEmitter<AlertInput>();
  @Output() private oneViewClicked = new EventEmitter<any>();

  public hasDefaultEcrmOpportunityId = true;
  public commitmentMapType = CommitmentMapType.Opportunity;
  public commitmentMapTypeEnum = CommitmentMapType;
  public pageTemplate: IPageTemplate;
  public alertInput: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  
  showUpdateCommitmentsIntakeDrawer = false;
  getFulfillmentSuggestionsClicked = false;
  getOpportunitySuggestionsClicked = false;
  originCommitments: Array<any> = [];
  commimentsTobeUpdated: Array<ClientCommitmentDTO> = [];

  showChildElement = false;
  currentIndex: number;
  enableSaveButton: boolean = false;
  statusClass: string;

  private zindexCounter = 0;
  private _isRefresh: boolean = false;
  @Input() isReset: boolean = false;

  public responseId = 0;
  public projectId = '';
  public projectDataSource = '';

  public fulfillmentTitle = '';
  public isCalledForMap = true;
  public idForDelete = 0;
  public showCopyModal = false;
  public showMapFulfilmentModal = false;
  public showDelegateModal = false;
  public showClientUserModal = false;
  public showClientUserEditModal = false;
  public hasMapOrUnmapPermission = false;
  public canMapOrUnmapOnMapModal = false;
  public selectedForCopy: ClientCommitmentDTO;
  public selectedForeditUserAssignement: ClientCommitmentDTO;
  public selectedForUserAssignement: ClientCommitmentDTO;
  public isPageTemplateAdmin = false;
  public isClientExecutive = false;
  public isCCTAdmin = false;
  public isRm = false;
  // public isRestrictedAdminReadOnly = false;
  public isCCTAdminReadOnly = false;
  public titleForUnmap = '';
  public pageTemplateResponseDetailsId = 0;
  private openUnmapModal = false;
  public zIndexMode = false;
  private scrollPlaceWhereMapFulfillmentCalled = '';

  userData: IUser;
  disableFormFieldApiKeys = ['riskReason'];
  isEdit: boolean = false;
  /** start of column settings changes**/
  columnPreferences: any;
  prefColumnOptions: any;
  listName: any;
  preferenceId: any;
  userId: any;
  refreshGrid: boolean = false;
  showTrackAll: boolean = false;
  disabledCustomCommitment: boolean = false;
  disabledNonCustomCommitment: boolean = false;
  maxColumnCount: any;
  showAddColumnOptions: boolean = false;
  isError: boolean = false;
  showColumnSettingsDrawer: boolean = false;
  searchByFormFieldJson?: string;
  columnSettingErrorMsg: string = '';
  displayColumns: any; // = ['ID', 'Page Name', 'Description', 'Due Date', 'Status', 'Submitted By','Submitted On'];
  keys: any;
  pageTemplateNameDescription = '';
  private ignoreSortingColumns = ['submittedByName', 'currentApproverName', 'pageTemplateResponseStatusTitle', 'editAccessWith'];
  content: any = [];
  trackedState: any = {};
  /** end of column settings changes*/

  formFieldChangeCounter: number = 0;
  isReadOnlyMode: boolean;
  currentCommimentDataSource: string;
  commitmentIntakeDictUrl: string = 'https://fiservcorp.sharepoint.com/:b:/s/fgsbsg/EfnqfNE5vb1JlKOQTS2ka1sB5C-pzH_tkIYKybAw5SXeUA?e=qQZEjV';
  isAllTracked: boolean = false;
  unnecessaryProductNameCharacters = ['"', '[', ']'];
  blueThemeMode = false;

  constructor(
    private router: Router,
    private manageForm: ManageFormsService,
    private userService: UserService,
    private excelService: ExportToExcelService,
    private clientCommitmentsService: ClientCommitmentsService,
    private pageTemplateService: PageTemplateService,
    private permission: PermissionsService,
    private preferenceService: PreferenceService,
    private contentService: ContentService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      if (this._isRefresh || this.isReset) {
        this.commimentsTobeUpdated = [];
        this.enableSaveButton = false;
        this.originCommitments = [];
        this.isAllTracked = false;
        this.trackedState = {};
      }
      else if (this.commimentsTobeUpdated && this.commimentsTobeUpdated.length > 0) {
        this.data.forEach((element) => {
          const currentSelected = this.commimentsTobeUpdated.find(c => c.id === element.id);
          if (currentSelected) {
            element.selected = !currentSelected.selected ? false : currentSelected.selected;
            element.hasTrackChanged = !currentSelected.hasTrackChanged ? false : currentSelected.hasTrackChanged;
            element.customeFormFieldListJsonAnswer.isRisk = !currentSelected.customeFormFieldListJsonAnswer.isRisk ? false : currentSelected.customeFormFieldListJsonAnswer.isRisk;
            element.customeFormFieldListJsonAnswer.riskReason = !currentSelected.customeFormFieldListJsonAnswer.riskReason ? '' : currentSelected.customeFormFieldListJsonAnswer.riskReason;
            element.customeFormFieldListJsonAnswer.isTracked = !currentSelected.customeFormFieldListJsonAnswer.isTracked ? false : currentSelected.customeFormFieldListJsonAnswer.isTracked;
            element.customeFormFieldListJsonAnswer.isReviewed = !currentSelected.customeFormFieldListJsonAnswer.isReviewed ? false : currentSelected.customeFormFieldListJsonAnswer.isReviewed;
            element.customeFormFieldListJsonAnswer.riskDescription = !currentSelected.customeFormFieldListJsonAnswer.riskDescription ? '' : currentSelected.customeFormFieldListJsonAnswer.riskDescription;
          }
        });
      }

      changes.data.currentValue.forEach((element) => {
        const item = {
          id: element.id,
          isRisk: !element.customeFormFieldListJsonAnswer?.isRisk ? false : element.customeFormFieldListJsonAnswer?.isRisk,
          isLinked: element.isLinked,
          riskReason: !element.customeFormFieldListJsonAnswer?.riskReason ? '' : element.customeFormFieldListJsonAnswer?.riskReason,
          isTracked: !element.customeFormFieldListJsonAnswer?.isTracked ? false : element.customeFormFieldListJsonAnswer?.isTracked,
          isReviewed: !element.customeFormFieldListJsonAnswer?.isReviewed ? false : element.customeFormFieldListJsonAnswer?.isReviewed,
          riskDescription: !element.customeFormFieldListJsonAnswer?.riskDescription ? '' : element.customeFormFieldListJsonAnswer?.riskDescription,
        };

        if (!this.originCommitments.some(c => c.id === element.id) || this._isRefresh) {
          this.originCommitments.push(item);
        }
      });

      this.isAllTracked = this.checkIsTrackAll();
      this._isRefresh = false;
    }

    $('#refreshTask').addClass('fa-rotation');
  }

  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.maxColumnCount = PreferenceService.maxColumnCount;
    this.listFormFieldJson = '';
    this.userData = this.userService.getUserListData();
    this.isCCTAdmin = this.permission.isCCTAdmin();
    this.isRm = this.permission.isRelationShipManager();
    this.isClientExecutive = this.permission.isClientExecutive();
    if (this.isCCTAdmin && this.selectedTabName.toLowerCase() == 'ecrm') {
      this.showTrackAll = true;
    }
    else if (!this.isCCTAdmin && (this.selectedTabName.toLowerCase() == 'ecrm' || this.selectedTabName.toLowerCase() == 'tracked')) {
      this.showTrackAll = false;
    }
    else {
      this.showTrackAll = true;
    }
    $('#refreshTask').addClass('fa-rotation');
  }

  onFieldChanged(row: any, fieldName: string, childField = '', bulkChange: boolean = false) {
    this.alertInput.message = '';
    this.alertChanged.emit(this.alertInput);
    if (fieldName === 'isTracked') {
      row.customeFormFieldListJsonAnswer['isReviewed'] = row.customeFormFieldListJsonAnswer[fieldName];
    }
    this.trackingCommitmentToUpdate(row, bulkChange);
    this.updateDependentField(row, fieldName, childField);
  }

  trackingCommitmentToUpdate(row, bulkChange: boolean = false): void {
    const original = this.originCommitments.find(o => o.id === row.id);
    if (!original || original.id !== row.id) {
      return;
    }

    if (!bulkChange) {
      if (row.isCustomClientCommitment) {
        this.disabledNonCustomCommitment = true;
      }
      else if (!row.isCustomClientCommitment) {
        this.disabledCustomCommitment = true;
      }
    }

    this.commimentsTobeUpdated = this.commimentsTobeUpdated.filter(
      (el) => el.id !== row.id
    );

    // Untrack
    const isUntracked = original.isTracked && !row.customeFormFieldListJsonAnswer.isTracked;
    if (isUntracked) {
      row.statusA = 'untrack';
      row.hasTrackChanged = true;
      this.isAllTracked = false;
      this.setTrackedState(false);
      this.commimentsTobeUpdated.push({ ...row });
    }
    else {
      const isTrackChanged = original.isTracked !== row.customeFormFieldListJsonAnswer.isTracked;
      const fieldIsRisk = this.customFormFieldListJson.find(e => e.formfieldapikey === 'isRisk');
      const hasRiskChanged = this.isDependentFieldsChanged(row, original, fieldIsRisk);
      const isReviewedChanged = original.isReviewed !== row.customeFormFieldListJsonAnswer.isReviewed;
      const hasChanged = isTrackChanged || isReviewedChanged || hasRiskChanged;
      if (hasChanged) {
        row.statusA = 'update';
        if (!this.isValidRisk(row.customeFormFieldListJsonAnswer) || !isTrackChanged) {
          delete row.hasTrackChanged;
        }
        else if (isTrackChanged) {
          row.hasTrackChanged = true;
        }

        this.commimentsTobeUpdated.push({ ...row });
      }
      else {
        delete row.hasTrackChanged;
        if (bulkChange && row.isCustomClientCommitment) {
          this.disabledCustomCommitment = false;
        }
        else if (bulkChange && !row.isCustomClientCommitment) {
          this.disabledNonCustomCommitment = false;
        }
        else if (!bulkChange && row.isCustomClientCommitment) {
          this.disabledNonCustomCommitment = false;
        }
        else if (!bulkChange && !row.isCustomClientCommitment) {
          this.disabledCustomCommitment = false;
        }
      }

      if (!original.isTracked && !row.customeFormFieldListJsonAnswer.isTracked && this.isAllTracked) {
        this.isAllTracked = false;
        this.setTrackedState(false);
      }
      else if (row.customeFormFieldListJsonAnswer.isTracked && !bulkChange && !this.isAllTracked) {
        const dataCanTracked = this.getPossibleTrackedData();
        const isAllTracked = dataCanTracked && dataCanTracked.length > 0 && dataCanTracked.every(c => c.customeFormFieldListJsonAnswer.isTracked);
        if (isAllTracked) {
          this.isAllTracked = true;
          this.setTrackedState(true);
        }
      }
      else if (row.customeFormFieldListJsonAnswer.isTracked && bulkChange && this.isAllTracked) {
        const dataCanTracked = this.getPossibleTrackedData();
        const isAllTrackedWithoutChanged = dataCanTracked.every(c => !c.hasTrackChanged && c.customeFormFieldListJsonAnswer.isTracked);
        if (isAllTrackedWithoutChanged) {
          this.isAllTracked = false;
          this.setTrackedState(false);
        }
      }
    }

    this.enableSaveButton = this.commimentsTobeUpdated.length > 0;
  }

  checkDependentFieldHasError(row: any, parentFieldKey: string, childFieldKey: string) {
    const { customFormFieldListJson } = this;
    if (customFormFieldListJson.length === 0) {
      return false;
    }

    const dependentChildField = customFormFieldListJson.find(e => e.formfieldapikey === childFieldKey);
    if (!dependentChildField?.dependentParentField) {
      return false;
    }

    const formFieldListJsonAnswer = row.customeFormFieldListJsonAnswer;
    if (!formFieldListJsonAnswer[parentFieldKey] && formFieldListJsonAnswer[parentFieldKey] !== 'true') {
      return false;
    }

    const childFieldIndex = customFormFieldListJson.findIndex((e) => e.formfieldapikey === childFieldKey);
    return !formFieldListJsonAnswer[childFieldKey] && customFormFieldListJson[childFieldIndex].isMandatory == true;
  }

  private validateDependentFields() {
    this.isError = false;
    const { customFormFieldListJson } = this;
    if (customFormFieldListJson.length === 0) {
      return;
    }

    for(const updatedRow of this.commimentsTobeUpdated) {
      const original = this.originCommitments.find(o => o.id === updatedRow.id);
      for (const currentField of customFormFieldListJson) {
        const currentFieldKey = currentField.formfieldapikey;
        if (!currentFieldKey) {
          continue;
        }
      
        const formFieldListJsonAnswer = updatedRow.customeFormFieldListJsonAnswer;
        if (!formFieldListJsonAnswer[currentFieldKey] && formFieldListJsonAnswer[currentFieldKey] !== 'true') {
          continue;
        }
      
        if (!currentField.dependentChildField) {
          continue;
        }
      
        const childField = customFormFieldListJson.find(e => e.formfieldapikey === currentField.dependentChildField);
        const isDependentFieldAnswered = formFieldListJsonAnswer[childField.formfieldapikey];
        if (!childField.isMandatory || (isDependentFieldAnswered && isDependentFieldAnswered !== 'other')) {
          continue;
        }

        const hasRiskChanged = this.isDependentFieldsChanged(updatedRow, original, currentField);
          // Bypass the error if the user untrack a cancelled project
        const isCancelledOrReadOnly = this.isCancelledOrReadOnlyCommitment(updatedRow, currentField);
        if (isCancelledOrReadOnly && (!formFieldListJsonAnswer?.isTracked && updatedRow['statusA'] === 'untrack') && !hasRiskChanged) {
          continue;
        }
      
        // Handle the case where the dependent field is a "other" field.
        let isValidRisk;
        if (childField.showOtherOptionField && formFieldListJsonAnswer[childField.formfieldapikey] === 'other') {
          isValidRisk = formFieldListJsonAnswer[childField.otherOptionFieldApiKey];
        }
      
        if (!isValidRisk) {
          this.alertInput.alertType = AlertType.Error;
          this.alertInput.message = 'Please provide details in all required highlighted fields.';
          this.alertChanged.emit(this.alertInput);
          this.showUpdateCommitmentsIntakeDrawer = false;
          $('.container-page').removeClass('overflow-hidden');
          this.isError = true;
          return;
        }
      }
    }

    return;
  }

  isDependentFieldsChanged(updatedRow: ClientCommitmentDTO, originalCommitment: ClientCommitmentDTO, currentField: any) {
    const formfieldapikey = currentField.formfieldapikey;
    const formFieldListJsonAnswer = updatedRow.customeFormFieldListJsonAnswer;
    const isCurrentFieldChanged = originalCommitment[formfieldapikey] !== formFieldListJsonAnswer[formfieldapikey];
    if (isCurrentFieldChanged) {
      return true;
    }
    
    const childField = this.customFormFieldListJson.find(e => e.formfieldapikey === currentField.dependentChildField);
    const originalChildValue = originalCommitment[childField.formfieldapikey] ?? '';
    const updatedChildValue = formFieldListJsonAnswer[childField.formfieldapikey] ?? '';
    const isChildFieldChanged = originalCommitment[formfieldapikey] && formFieldListJsonAnswer[formfieldapikey] && originalChildValue !== updatedChildValue;
    if (isChildFieldChanged) {
      return true;
    }

    const { otherOptionFieldApiKey } = childField;
    const originalOtherOptionValue = originalCommitment[otherOptionFieldApiKey] ?? '';
    const updatedOtherOptionValue = formFieldListJsonAnswer[otherOptionFieldApiKey] ?? '';
    if (!originalOtherOptionValue && !updatedOtherOptionValue) {
      return false;
    }

    const isOtherOptionFieldChanged = originalOtherOptionValue !== updatedOtherOptionValue;
    return isOtherOptionFieldChanged;
  }

  updateDependentField(row, parentField: string = '', childField: string = '') {
    if (!parentField || !childField) {
      return;
    }

    if (!row.customeFormFieldListJsonAnswer[parentField] || row.customeFormFieldListJsonAnswer[parentField] === 'false') {
      row.customeFormFieldListJsonAnswer[childField] = '';
    }
  }

  refresh() {
    this._isRefresh = true;
    $('#refreshTask').addClass('fa-rotation');
    this.callSearch();
    this.disabledNonCustomCommitment = false;
    this.disabledCustomCommitment = false;
    $('#refreshTask').removeClass('fa-rotation');
  }

  transformDataSourceString(text: string) {
    if (!text) return '';

    if (text === 'ecrm') {
      return 'eCRM';
    }
    else if (text === 'cims') {
      return 'CIMS';
    }
    else {
      return text.slice(0, 1).toUpperCase() + text.slice(1);
    }
  }

  getOpportunitySuggestions(item: ClientCommitmentDTO): void {
    this.getFulfillmentSuggestionsClicked = false;
    this.getOpportunitySuggestionsClicked = false;
    item.opportunitySuggestionsIsCalled = false;
    item.fulfillmentSuggestionsIsCalled = false;
    this.alertInput.message = '';
    this.clientCommitmentsService.getMappingSuggestions(item.id, {
      commitmentDataSource: "ecrm"
    }).subscribe(
      (res: any) => {
        this.data.forEach(element => {
          if (element.id === item.id) {
            element.opportunitySuggestions = res.body;
          }
        });
        item.opportunitySuggestionsIsCalled = true;
        this.getOpportunitySuggestionsClicked = true;
      },
      (error) => {
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
        this.alertChanged.emit(this.alertInput);
        this.getOpportunitySuggestionsClicked = true;
        item.opportunitySuggestionsIsCalled = true;
      }
    );

    this.currentIndex = item.id;
  }

  getFulfillmentSuggestions(item: ClientCommitmentDTO): void {
    this.getFulfillmentSuggestionsClicked = false;
    this.getOpportunitySuggestionsClicked = false;
    item.opportunitySuggestionsIsCalled = false;
    item.fulfillmentSuggestionsIsCalled = false;
    this.alertInput.message = '';
    this.clientCommitmentsService.getMappingSuggestions(item.id, {
      commitmentDataSource: "clarity"
    }).subscribe(
      (res: any) => {
        this.data.forEach(element => {
          if (element.id === item.id) {
            element.fulfillmentSuggestions = res.body;
          }
        });
        item.fulfillmentSuggestionsIsCalled = true;
        this.getFulfillmentSuggestionsClicked = true;
      },
      (error) => {
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
        this.alertChanged.emit(this.alertInput);
        this.getFulfillmentSuggestionsClicked = true;
        item.fulfillmentSuggestionsIsCalled = true;
      }
    );

    this.currentIndex = item.id;
  }

  removeSuggestions(item: any): any {
    // Just remove on UI
    this.currentIndex = -1;
  }

  onSave(): void {
    this.validateDependentFields();
    if (this.isError) {
      return;
    }
    this.showUpdateCommitmentsIntakeDrawer = true;
    this.alertInput.message = '';
    this.alertChanged.emit(this.alertInput);
    $('.container-page').addClass('overflow-hidden');
    this.scrollToTop();
  }

  public openMapFulfillment(item: any, i: number, isCalledForMap: boolean, projectDataSource: string, suggestion?: any): void {
    this.hasDefaultEcrmOpportunityId = item.customeFormFieldListJsonAnswer?.hasDefaultEcrmOpportunityId;
    if (isCalledForMap) {
      this.canMapOrUnmapOnMapModal = this.hasMapOrUnmapPermission && !this.isReadOnlyCommiment(item.accessLevel);
      this.fulfillmentTitle = item.title;
      this.projectDataSource = projectDataSource;
      if (!projectDataSource) {
        this.projectId = '';
        this.commitmentMapType = this.commitmentMapTypeEnum.Project;
      }
      else if (suggestion && projectDataSource == 'clarity') {
        this.projectId = suggestion.projectNumber;
        this.fulfillmentTitle = suggestion.title;
        this.commitmentMapType = this.commitmentMapTypeEnum.Project;
      }
      else if (suggestion && projectDataSource == 'ecrm') {
        this.projectId = suggestion.opportunityId;
        this.fulfillmentTitle = suggestion.title;
        this.commitmentMapType = this.commitmentMapTypeEnum.Opportunity;
      }
    }
    else if (projectDataSource == 'ecrm') {
      this.fulfillmentTitle = '';
      this.projectId = item.customeFormFieldListJsonAnswer.opportunityId;
      this.projectDataSource = projectDataSource;
      this.commitmentMapType = this.commitmentMapTypeEnum.Opportunity;
      this.canMapOrUnmapOnMapModal =
        this.hasMapOrUnmapPermission && item.dataSource !== 'ecrm' &&
        !this.isReadOnlyCommiment(item.accessLevel) &&
        !item.customeFormFieldListJsonAnswer?.hasDefaultEcrmOpportunityId;
    }
    else {
      this.fulfillmentTitle = '';
      this.projectId = this.getProjectIdFromHdnFulfillmentDetails(item);
      this.projectDataSource = this.getProjectDataSourceFromHdnFulfillmentDetails(item);
      this.commitmentMapType = this.commitmentMapTypeEnum.Project;
      this.canMapOrUnmapOnMapModal = this.hasMapOrUnmapPermission && item.dataSource !== 'clarity' && item.dataSource !== 'servicenow' && !this.isReadOnlyCommiment(item.accessLevel);
    }

    this.responseId = item.id;
    this.isCalledForMap = isCalledForMap;
    this.isReadOnlyMode = this.isReadOnlyCommiment(item.accessLevel);
    this.scrollPlaceWhereMapFulfillmentCalled = 'actions-' + i, item.accessLevel;
    this.currentCommimentDataSource = item.dataSource;
    if (this.openUnmapModal) {
      this.openUnmapModal = false;
      this.loadMappedProjectDetails();
    } else {
      setTimeout(() => {
        this.showMapFulfilmentModal = true;
        $('#containerPage').addClass('overflow-hidden');
      }, 10);
    }
  }

  public closeMapFulfilmentDrawer(): void {
    $('#containerPage').removeClass('overflow-hidden');
    this.showMapFulfilmentModal = false;
    const element = document.getElementById(this.scrollPlaceWhereMapFulfillmentCalled);
    element.scrollIntoView();
  }

  public onOpenUnmapModal(): void {
    this.openUnmapModal = true;
    this.titleForUnmap = '';
    this.pageTemplateResponseDetailsId = 0;
  }

  public openMapFulfillmentForKeydown(item: any, i: number, isCalledForMap: boolean, projectDataSource: string, suggestion?: any): void {
    if (document.activeElement.tagName !== 'A') {
      this.openMapFulfillment(item, i, isCalledForMap, projectDataSource, suggestion);
    }
  }

  private loadMappedProjectDetails(): void {
    this.zIndexMode = true;
    $('#refreshTask').addClass('fa-rotation');
    this.alertInput.message = '';
    this.clientCommitmentsService.getMappedProjectDetails(environment.clientCommitmentPageTemplateId, this.projectDataSource, this.responseId).subscribe(
      data => {
        const x = data as unknown as PageTemplateResponseDetailsDTO;
        this.titleForUnmap = x.title;
        this.pageTemplateResponseDetailsId = x.id;
        $('#refreshTask').removeClass('fa-rotation');
      },
      error => {
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
        this.alertChanged.emit(this.alertInput);
        $('#refreshTask').removeClass('fa-rotation');
      });
  }

  getPossibleTrackedData(): ClientCommitmentDTO[] {
    if (!this.customFormFieldListJson) {
      return [];
    }

    const colIsTracked = this.customFormFieldListJson.find(e => e.formfieldapikey === "isTracked");
    const dataCanTracked = this.data.filter(c => {
      const canTrack = !this.shouldDisableIsTrackCheckbox(c, colIsTracked);
      if (!canTrack) {
        return false;
      }

      return this.isValidRisk(c.customeFormFieldListJsonAnswer);
    });

    return dataCanTracked;
  }

  checkIsTrackAll() {
    if (!this.trackedState) {
      return false;
    }

    const dataCanTracked = this.getPossibleTrackedData();
    if (!dataCanTracked || dataCanTracked.length === 0) {
      return false;
    }

    const isAllTrackedWithoutChanged = dataCanTracked.every(c => !c.hasTrackChanged && c.customeFormFieldListJsonAnswer.isTracked);
    if (isAllTrackedWithoutChanged) {
      return false;
    }

    const isAllTracked = !dataCanTracked.some(c => !c.customeFormFieldListJsonAnswer.isTracked);
    return isAllTracked;
  }

  setTrackedState(isTracked: boolean) {
    const trackedField = `isPage${this.pagerObject.currentPage}Tracked`;
    this.trackedState[this.selectedTabName] = {
      ...this.trackedState[this.selectedTabName],
      [trackedField]: isTracked
    };
  }

  getTrackedState(): boolean {
    if (!this.pagerObject) {
      return false;
    }
    const trackedField = `isPage${this.pagerObject.currentPage}Tracked`;
    if (!this.trackedState[this.selectedTabName]) {
      return false;
    }
    return this.trackedState[this.selectedTabName][trackedField];
  }

  isValidRisk(answerJson) {
    if (!answerJson.isRisk) {
      return true;
    }

    if (!answerJson.riskReason) {
      return false;
    }

    const riskReason = answerJson.riskReason.toLowerCase();
    if (riskReason !== 'other') {
      return true;
    }

    return answerJson.riskDescription;
  }

  trackAll() {
    const currentTrackedState = this.getTrackedState();
    this.setTrackedState(!currentTrackedState);
    if (!this.data || this.data.length === 0) {
      return;
    }

    const colIsTracked = this.customFormFieldListJson.find(e => e.formfieldapikey === "isTracked");
    for (const row of this.data) {
      const canTrack = !this.shouldDisableIsTrackCheckbox(row, colIsTracked);
      if (!canTrack || row.isCustomClientCommitment) {
        if (row.isCustomClientCommitment && !currentTrackedState) {
          this.disabledCustomCommitment = true;
          continue;
        }
        else if (row.isCustomClientCommitment && currentTrackedState) {
          this.disabledCustomCommitment = false;
          continue;
        }
        else {
          continue;
        }
      }

      if (!this.isValidRisk(row.customeFormFieldListJsonAnswer)) {
        continue;
      }

      const original = this.originCommitments.find(o => o.id === row.id);
      let shouldRaiseChange = false;
      if (this.isAllTracked) {
        row.customeFormFieldListJsonAnswer.isTracked = true;
        shouldRaiseChange = true;
      }
      else if (original.isTracked !== row.customeFormFieldListJsonAnswer.isTracked) {
        row.customeFormFieldListJsonAnswer.isTracked = original.isTracked;
        shouldRaiseChange = true;
      }

      if (shouldRaiseChange) {
        this.onFieldChanged(row, "isTracked", null, true);
      }
    }
  }

  public sort(key: string, type: string): void {
    let column = '';
    if (type === 'dynamicColumn') {
      column = key;
    } else {
      const keyIndex = this.displayColumns.indexOf(key);
      column = this.keys[keyIndex];
    }

    if (this.filterColumn !== column) {
      this.filterColumn = column;
      this.isAsc = false;
    } else {
      this.isAsc = !this.isAsc;
    }

    if (type === 'dynamicColumn') {
      this.queryParam.sortingFormField = this.filterColumn;
      this.queryParam.sortingFormFieldOption = this.isAsc ? 'asc' : 'desc';
      this.queryParam.orderBy = null;
    } else {
      this.queryParam.sortingFormField = null;
      this.queryParam.sortingFormFieldOption = null;
      this.queryParam.orderBy = this.isAsc ? this.filterColumn : `${this.filterColumn} desc`;
    }

    this.queryParam.pageNumber = this.queryParam.pageNumber ?? 1;
    this.queryParam.pageSize = 10;
    this.callSearch();
  }

  public applySorting(key: string, type: string): boolean {
    let column = '';
    if (type === 'dynamicColumn') {
      column = key;
    } else {
      const keyIndex = this.displayColumns.indexOf(key);
      column = this.keys[keyIndex];
    }

    return !(this.ignoreSortingColumns.includes(column)) && (this.data.length > 0);
  }

  public displayColumnsSortIcon(column: string): boolean {
    if (this.filterColumn !== '') {
      const temp = this.columnPreferences.find((r: any) => r.title === column);
      if (temp) {
        return this.filterColumn === temp.id;
      }
    }
    return false;
  }

  public changePage(page: number): void {
    this.queryParam.pageNumber = page;
    this.callSearch();
  }

  public onCopyClicked(response: ClientCommitmentDTO): void {
    this.selectedForCopy = response;
    this.showCopyModal = true;
  }

  private callSearch(): void {
    this.search.emit(this.queryParam);
  }

  public onComponentSubmited(errorMessage: string): void {
    this.showCopyModal = false;
    this.queryParam.pageNumber = 1;    
    if (!errorMessage || errorMessage === ''){
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = 'Selected Fulfillment is deleted successfully.' ;
    }
    else{
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = 'Unable to delete the Selected Fulfillment.';
    }
    
    this.callSearch();
    this.alertChanged.emit(this.alertInput);
  }

  public onMapFulfilmentComponentSubmited(alertInput: AlertInput): void {
    $('#containerPage').removeClass('overflow-hidden');
    this.showMapFulfilmentModal = false;
    this.alertInput = alertInput;
    this.alertChanged.emit(this.alertInput);
    this.callSearch();
  }

  public onViewClicked(pageResponseId: number): void {
    const pageResponse = this.data.find((r) => r.id === pageResponseId);
    const responseDetails: any = {
      clientId: pageResponse.clientId,
      clientTitle: pageResponse.clientTitle,
      organizationId: pageResponse.organizationId,
      organizationTitle: pageResponse.organizationTitle,
      productId: pageResponse.productId,
      productTitle: pageResponse.productTitle,
    };
    this.pageTemplateService.formVal = responseDetails;
    this.router.navigate([
      `/self-service/pageTemplate/${environment.clientCommitmentPageTemplateId}/response`,
      pageResponseId,
    ]);
  }

  public onEditClicked(pageResponseId: number): void {
    this.clientCommitmentsService.getPageTemplateResponseById(pageResponseId)
      .subscribe((response) => {
        const data = response as IPageTemplateResponse;
        const filedSets = JSON.parse(data.formJSON);
        const fieldSets2 = filedSets.components.filter((data) => {
          if (data.type == 'panel' || data.type == 'fieldset') {
            data.type = 'panel';
            data.legend = data.title;
            return true;
          }
        });
        filedSets.components = fieldSets2;
        const dataForm: ICreateForm = {
          id: pageResponseId,
          formDes: data.description,
          templateOption: '',
          formTemplate: '',
          formName: data.title,
          initialDataJSON: data.formJSON,
          json: JSON.stringify(filedSets),
          isExist: true,
          isResponse: true,
          rowVersion: data.rowVersion,
          answerJSON: data.answerJSON,
          assignedToName: data.currentAssignedTo,
          currentApproverName: data.currentApproverName,
          dueDate: data.dueDate,
          responseStatusId: data.pageTemplateResponseStatusId,
          type: 'page',
          pageTemplateId: data.pageTemplateId,
        };
        this.manageForm.saveFormValues(dataForm);
        this.router.navigate(['/admin/form/template']);
      });
  }

  public exportExcel(): void {
    const pageNumbers: number[] = [];
    const chunkSize = 500;
    const totalPages = Math.ceil(this.pagerObject.totalCount / chunkSize);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const apiCall = [];
    for (const pageNumber of pageNumbers) {
      const req = this.clientCommitmentsService.exportPageTemplateResponseList(environment.clientCommitmentPageTemplateId, {
        ...this.queryParam,
        pageSize: chunkSize,
        pageNumber: pageNumber,
      }).pipe(map(httpResponse => {
        const pageResponses = httpResponse.body as any[];
        return pageResponses.map(pageResponse => {
          return this.populateExportedResponse(pageResponse);
        });
      }));
      apiCall.push(req);
    }

    forkJoin(apiCall).subscribe(
      responses => {
        const exportResponses = [];
        responses.forEach(response => {
          (response as any[]).forEach(rs => {
            exportResponses.push(rs);
          });
        });
        this.excelService.exportAsExcelFile(exportResponses, `${this.pageTemplateNameTitle}_responses`);
      },
      (error) => {
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
        this.alertChanged.emit(this.alertInput);
      });
  }


  public exportPTExcel(): void {
    const apiCall = [];

    const req = this.clientCommitmentsService.exportPTResponseList(environment.clientCommitmentPageTemplateId, {
      ...this.queryParam,
      pageNumber: 1,
    }, this.pagerObject.totalCount).pipe(map(httpResponse => {
      const pageResponses = httpResponse.body as any[];
      return pageResponses.map(pageResponse => {
        return this.populateExportedResponse(pageResponse);
      });
    }));
    apiCall.push(req);

    forkJoin(apiCall).subscribe(
      responses => {
        const exportResponses = [];
        responses.forEach(response => {
          (response as any[]).forEach(rs => {
            exportResponses.push(rs);
          });
        });
        this.excelService.exportAsExcelFile(exportResponses, `${this.pageTemplateNameTitle}_responses`);
      },
      (error) => {
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
        this.alertChanged.emit(this.alertInput);
      });
  }

  populateExportedResponse(pageResponse: any) {
    const exportResponse = {};
    //exportResponse['Id'] = pageResponse['id'];
    //exportResponse['Page Template Id'] = pageResponse['pageTemplateId'];
    //exportResponse['Title'] = pageResponse['title'];
    //exportResponse['Description'] = pageResponse['description'];

    const answerJSON = JSON.parse(pageResponse.answerJSON);
    if (answerJSON && this.customFormFieldListJson.length > 0) {
      for (const item of this.customFormFieldListJson) {
        const fieldLabel = item['formfieldlabel'].replace("Commitment Status", "Stage");

        const apiKey = item['formfieldapikey'].replace("statusIntake", "statusIntake");
        const otherOptionApiKey = item['otherOptionFieldApiKey'];
        const apiKeyValue = answerJSON[apiKey];
        if (item['formfieldtype'] === 'select' && item['showOtherOptionField'] === true && apiKeyValue === 'other') {
          exportResponse[fieldLabel] = `${apiKeyValue} - ${answerJSON[otherOptionApiKey]}`;
        } else if (apiKeyValue && typeof (apiKeyValue) === 'object') {
          const data = apiKeyValue.map(s => s.replace(/^"|"$/g, '').trim());
          exportResponse[fieldLabel] = data.join(",");
        } else {
          if (item['formfieldapikey'] === 'oneViewGroupName') {
            exportResponse[fieldLabel] = pageResponse.oneViewGroupName;
          } else {
            exportResponse[fieldLabel] = apiKeyValue;
          }
          
        }
      }
    }
    exportResponse['Data Source'] = answerJSON.datasource;
    // exportResponse['Current Assignee'] = pageResponse['editAccessWith'];
    //exportResponse['Current Approver Name'] = pageResponse['currentApproverName'];
    //exportResponse['Approval Schema'] = pageResponse['approvalSchema'];
    //exportResponse['Client Title'] = pageResponse['clientTitle'];
    //exportResponse['Product Title'] = pageResponse['productTitle'];
    //exportResponse['Organization Title'] = pageResponse['organizationTitle'];
    // exportResponse['Start Date'] = pageResponse['startDate'];
    // exportResponse['Due Date'] = pageResponse['dueDate'];
    // exportResponse['Submitted By'] = pageResponse['submittedByName']; 
    

    var createdDate = new Date(pageResponse['createdOn']);
    createdDate.setHours(createdDate.getHours() - 5);
    exportResponse['Submitted On'] = formatDate(createdDate, 'M/d/yyyy HH:mm', 'en-US');

    exportResponse['Modified By'] = pageResponse['modifiedByName'];

    var modifiedDate = new Date(pageResponse['modifiedOn']);
    modifiedDate.setHours(modifiedDate.getHours() - 5);
    exportResponse['Modified On'] = formatDate(modifiedDate, 'M/d/yyyy HH:mm', 'en-US');

    exportResponse['Planned/ Committed Delivery Date'] = this.formatDateTime(this.plannedCommitteDeliveryDateGenerator(answerJSON), 'M/d/yyyy HH:mm:ss a', 'en-US');
    if (exportResponse['Stage'] == 'Canceled') {
      exportResponse['Stage'] = "Canceled"
    }
    exportResponse['Client Users'] = pageResponse['clientUsersString'];
    return exportResponse;
  }

  public onDeleteModalClicked(id: number): void {
    this.idForDelete = id;
  }

  public openDelegateModal(pageResponseId: number) {
    this.responseId = pageResponseId;
    this.showDelegateModal = true;
  }

  successDelegateClick(event: AlertInput) {
    this.alertInput = event;
    this.alertChanged.emit(this.alertInput);
    this.refresh();
  }

  saveUsersClick() {
    this.showClientUserModal = false;
    this.onSave();
  }

  onEditUsersClicked(pageResponse: any) {
    this.selectedForeditUserAssignement = pageResponse;
    this.showClientUserEditModal = true;
  }

  closeAddCommitmentUsersModal() {
    this.showClientUserModal = false;
    this.refresh();
  }

  onAddUsersClicked(pageResponse: any) {
    this.selectedForUserAssignement = pageResponse;
    if (!this.selectedForUserAssignement.customeFormFieldListJsonAnswer.isTracked && (JSON.parse(this.selectedForUserAssignement.answerJSON).isTracked != undefined && JSON.parse(this.selectedForUserAssignement.answerJSON).isTracked != true)) {
      this.showClientUserModal = true;
    }    
  }

  editCommitmentUsersModal() {
    this.showClientUserEditModal = false;
    this.showClientUserModal = false;
    this.refresh();
  }


  /*
  The condition to show the delegate option:
 
  User has role in (Admin, Restricted Admin, Relationship manager, CCT Admin) OR  user is PageTemplate Admin OR user is submitted user
  AND pageTemplateResponse status in (Not started, In Progress)
  AND commiment is not read only
  AND commiment is tracked
*/
  canDelegate(item: ClientCommitmentDTO): boolean {
    if (this.isReadOnlyCommiment(item.accessLevel) || !this.hasRolesCanDelegateCommitment()) {
      return false;
    }

    const currentItem = this.originCommitments.find(i => i.id === item.id);
    const validRole = this.hasRolesCanDelegateCommitment() || this.userData.id == item.submittedById.toString();

    const validStatus = validRole
      && !item.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
      && item.accessLevel === 'Write'
      && currentItem.isTracked;

    return validStatus;
  }

  submitRequest() {
    this.alertInput.message = '';
    this.alertChanged.emit(this.alertInput);
    const updatedList = [...this.commimentsTobeUpdated];
    const data = JSON.parse(JSON.stringify(updatedList));
    data.forEach((item) => {
      item['requestType'] = 'update';
      item.answerJSON = JSON.stringify(item.customeFormFieldListJsonAnswer);
      delete item.customeFormFieldListJsonAnswer;
    });
    this.clientCommitmentsService
      .updateMultiplePageResponses(data)
      .subscribe(
        () => {
          this.alertInput.alertType = AlertType.Success;
          this.alertInput.message = 'Selected Response(s) is updated successfully.';
          this.alertChanged.emit(this.alertInput);
          this._isRefresh = true;
          this.closeUpdateCommitmentModal();
          this.queryParam.pageNumber = 1;
          $('#refreshTask').addClass('fa-rotation');
          this.callSearch();
        },
        (error) => {
          this.alertInput.alertType = AlertType.Error;
          this.alertInput.message = error;
          this.alertChanged.emit(this.alertInput);
          this.closeUpdateCommitmentModal();
        }
      );

    $('.container-page').removeClass('overflow-hidden');
  }

  closeUpdateCommitmentModal() {
    this.showUpdateCommitmentsIntakeDrawer = false;
    $('.container-page').removeClass('overflow-hidden');
    this.refresh();
  }

  public addZIndex(i: number): void {
    document.getElementById('actions-' + i).style.zIndex = (++this.zindexCounter).toString();
  }

  /** start of column settings changes**/
  showColumnSettings() {
    $('.container-page').addClass('overflow-hidden');
    this.showColumnSettingsDrawer = true;
    this.columnSettingErrorMsg = '';

    setTimeout(() => {
      const modal = document.getElementById('closeButton');
      modal.focus();
    }, 1000);
  }

  callPreferenceGetService() {
    if (!this.userData) return;

    const userId = this.userData.id;
    const listName = PreferenceService.pageTemplateResponseList;
    const fetchPreferenceByUserId = this.preferenceService.getPreferenceByUserId(userId, listName, this.pageTemplate.id);
    const fetchAllPreferenceByListName = this.preferenceService.getAllPreferenceByListName(listName, this.pageTemplate.id);
    this.showAddColumnOptions = true;
    forkJoin([fetchPreferenceByUserId, fetchAllPreferenceByListName])
      .subscribe((response: any) => {
        const columnPrefs = response[0];
        const columnList = response[1];
        this.refreshPreference(columnPrefs, columnList);
      }, error => {
        this.isError = true; this.columnSettingErrorMsg = error;
      }
      );
  }

  savePreference() {
    const hasEmpty = this.preferenceService.hasEmptyColumns(this.columnPreferences);
    if (hasEmpty) {
      this.isError = true;
      this.columnSettingErrorMsg = "Empty column selected.";
      return;
    }

    const preferences = this.preferenceService.getPreferencesToBeSaved(this.columnPreferences, this.prefColumnOptions)
    const isDuplicate = this.preferenceService.checkForDuplicates(preferences);
    if (isDuplicate) {
      this.columnSettingErrorMsg = "Duplicate column selected";
      return;
    }
    const params: any = {
      userId: this.userData.id,
      listName: this.listName,
      columnOptions: JSON.stringify(preferences),
      pageTemplateId: this.pageTemplate.id
    };

    if (this.userId != 0) {
      params.id = this.preferenceId;
      this.updatePreference(params);
    } else {
      this.createPreference(params);
    }

    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
  }

  createPreference(params: any) {
    this.preferenceService.savePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this.isError = true; this.columnSettingErrorMsg = error;
        }
      );
  }

  updatePreference(params: any) {
    this.preferenceService.updatePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this.isError = true; this.columnSettingErrorMsg = error;
        }
      );
  }

  refreshPreference(columnPrefs: any, columnList?: any) {
    this.isError = false;
    const columnOptions = JSON.parse(columnPrefs.columnOptions);
    this.listName = columnPrefs.listName;
    this.userId = columnPrefs.userId;
    this.preferenceId = columnPrefs.id;
    this.columnPreferences = this.preferenceService.convertToDropDownItems(columnOptions);
    if (this.columnPreferences && this.columnPreferences.length < this.maxColumnCount) {
      this.showAddColumnOptions = true;
    } else {
      this.showAddColumnOptions = false;
    }
    if (columnList) {
      const listOfoptions = JSON.parse(columnList.allColumnOptions);
      this.prefColumnOptions = this.preferenceService.convertToDropDownItems(listOfoptions);
    }
    const columnPreferences = this.preferenceService.refreshGrid(columnOptions);
    this.initDataTableColumns(columnPreferences);
    this.refreshGrid = true;
  }

  initDataTableColumns(preferences: any) {
    this.pageTemplateNameTitle = this.pageTemplate.title;
    this.pageTemplateNameDescription = this.pageTemplate.description;
    this.displayColumns = [];
    this.keys = [];

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] === 'id') {
        this.keys.push('id');
        this.displayColumns.push(preferences.displayColumns[i]);
      }

      if (preferences.keys[i] === 'title') {
        this.keys.push('title');
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }

    if (this.pageTemplate.searchByFormFieldJson === undefined || this.pageTemplate.searchByFormFieldJson === null || this.pageTemplate.searchByFormFieldJson === "")
      this.searchByFormFieldJson = JSON.parse('[]');
    else
      this.searchByFormFieldJson = JSON.parse(this.pageTemplate.searchByFormFieldJson);

    if (this.pageTemplate.formFieldListJson !== undefined && this.pageTemplate.formFieldListJson !== null && this.pageTemplate.formFieldListJson !== "") {
      this.listFormFieldJson = JSON.parse(this.pageTemplate.formFieldListJson);
      // if (this.listFormFieldJson.length > 0) {
      //   for (let item of this.listFormFieldJson) {
      //     this.ignoreSortingColumns.push(item['formfieldapikey']);
      //   }
      // }
    }
    else {
      this.listFormFieldJson = JSON.parse('[]');
    }

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] !== 'id' && preferences.keys[i] !== 'title') {
        this.keys.push(preferences.keys[i]);
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }
  }


  cancelPreference() {
    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
    this.callPreferenceGetService();
  }

  removeColumn(counter: any) {
    const columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount > 0) {
      this.columnPreferences.splice(counter, 1);
    }
  }

  addColumn() {
    const columnObject = Object.assign({}, this.columnPreferences[0]);
    let columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount < this.maxColumnCount) {
      this.columnPreferences.push(columnObject);
    }
    columnPrefsCount = this.columnPreferences.length;
    if (columnPrefsCount === this.maxColumnCount) {
      this.showAddColumnOptions = false;
    }
  }


  isPhone(key) {
    return key.toLowerCase().indexOf('phone') > -1;
  }
  isText(key) {
    return key.toLowerCase().indexOf('inputtext') > -1;
  }
  isPage(key) {
    return key.toLowerCase().indexOf('pageinputtext') > -1;
  }
  isStep(key) {
    return key.toLowerCase().indexOf('stepinputtext') > -1;
  }
  isDate(key) {
    return (key.indexOf('modifiedOn') > -1
      || key.indexOf('startDate') > -1
      || key.indexOf('modifiedON') > -1
      || key.indexOf('createdOn') > -1
      || key.indexOf('createdON') > -1
      || key.indexOf('startTime') > -1
      || key.indexOf('endTime') > -1);
  }
  isDateOnly(key) {
    return key.indexOf('dueDate') > -1 || key.indexOf('modifiedDate') > -1;
  }

  isStatus(key) {
    return (key === 'responseStatusTitle' || key === 'statusTitle' || key === 'status' || key === 'pageTemplateResponseStatusTitle');
  }

  isFlag(key) {
    return (key === 'isRisk');
  }

  getStatusCSSClass(val: any) {
    if (val == 'Overdue' || val == 'Failed') {
      this.statusClass = 'badge-table-status red';
    }
    else if (val == 'On Hold' || val == 'Delayed') {
      this.statusClass = 'badge-table-status hold';
    }
    else if (val == 'Cancel') {
      this.statusClass = 'badge-table-status gray';
    }
    else if (val == 'Rejected') {
      this.statusClass = 'badge-table-status rejected';
    }
    else if (val == 'Completed' || val == 'Success') {
      this.statusClass = 'badge-table-status green';
    }
    else if (val == 'Under Review' || val == 'Testing') {
      this.statusClass = 'badge-table-status keppel';
    }
    else if (val == 'In Progress' || val == 'InProgress') {
      this.statusClass = 'badge-table-status yellow';
    }
    else if (val == 'Not Started' || val == 'NotStarted' || val == 'Active') {
      this.statusClass = 'badge-table-status blue';
    }

    return this.statusClass;
  }
  /** end of column settings changes**/

  public getProjectIdFromHdnFulfillmentDetails(item: ClientCommitmentDTO): string {
    const hdnFulfillmentDetails = this.getMappedProjectNumberFromAnswerJson(item);
    return hdnFulfillmentDetails.split(': ')[1];
  }

  public getProjectDataSourceFromHdnFulfillmentDetails(item: ClientCommitmentDTO): string {
    const hdnFulfillmentDetails = this.getMappedProjectNumberFromAnswerJson(item);
    return hdnFulfillmentDetails.split(': ')[0];
  }

  public getMappedProjectNumberFromAnswerJson(item: ClientCommitmentDTO): string {
    return item.customeFormFieldListJsonAnswer.hdnFulfillmentDetails;
  }

  public unmap(errorMessage: string): void {
    if (errorMessage === '') {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = `Fulfillment "${this.projectId}" is unmapped successfully.`;
      this.callSearch();
    } else {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = errorMessage;
    }
    this.alertChanged.emit(this.alertInput);
  }

  /* Dynamic columns */

  formFieldChanged(row, parentField: string = '', childField: string = '') {

    const formFieldKey = `${parentField}OriginalValue`;
    if (row.customeFormFieldListJsonAnswer[formFieldKey] !== row.customeFormFieldListJsonAnswer[parentField])
      this.formFieldChangeCounter = this.formFieldChangeCounter + 1;
    else
      this.formFieldChangeCounter = this.formFieldChangeCounter - 1;

    if (parentField !== '' && childField !== '') {
      const childFormFieldKey = `${childField}OriginalValue`;
      if (row.customeFormFieldListJsonAnswer[childFormFieldKey] !== row.customeFormFieldListJsonAnswer[childField])
        this.formFieldChangeCounter = this.formFieldChangeCounter + 1;
    }

    if (this.formFieldChangeCounter > 0) row.selected = true;
    else row.selected = false;

    this.trackingCommitmentToUpdate(row);
  }

  public displayColumnData(item: ClientCommitmentDTO, key: any): string {
    if (key === 'parentId' && item[key] === 0) {
      return '';
    } else {
      return item[key];
    }
  }

  public isDisableFormFieldApiKey(formFieldApiKey: string): boolean {
    const result = this.disableFormFieldApiKeys.some(key => key === formFieldApiKey);

    return result;
  }

  public isReadOnlyCommiment(accessLevel: string): boolean {
    return (accessLevel === 'Read') || !this.hasRoleCanEditCommitment();
  }

  public canView(item: ClientCommitmentDTO): boolean {
    return !this.canViewEdit(item);
  }

  public canViewEdit(item: ClientCommitmentDTO): boolean {
    return !this.isReadOnlyCommiment(item.accessLevel) && (this.hasRoleCanEditCommitment());
  }

  public canEdit(): boolean {
    return this.userData.isAdmin;
  }

  public canMapProject(item: ClientCommitmentDTO): boolean {
    if (item.dataSource == 'servicenow' || item.dataSource == 'clarity' || item.dataSource == 'cims') {
      return false;
    }

    if (this.isReadOnlyCommiment(item.accessLevel)) {
      return false;
    }

    if (item.customeFormFieldListJsonAnswer?.hdnFulfillmentDetails) {
      return false;
    }

    if (this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin)) {
      return true;
    }

    return false;
  }

  public canDeleteCommitment(item: ClientCommitmentDTO): boolean {
    if (this.isReadOnlyCommiment(item.accessLevel) || !this.hasRolesCanDeleteCommitment()) {
      return false;
    }

    const result = !item.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
      && item.accessLevel === 'Write'
      && (this.isPageTemplateAdmin || (this.pageTemplate.isDeleteAllowed && this.hasRolesCanDeleteCommitment()));

    return result;
  }

  public canGetOpportunitySuggestion(item: ClientCommitmentDTO): boolean {
    if (item.customeFormFieldListJsonAnswer?.hasDefaultEcrmOpportunityId === true ||
      item.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled') {
      return false;
    }
    const result = environment.excludedDatasources.indexOf(item.dataSource) === -1
      && !this.isReadOnlyCommiment(item.accessLevel)
      && !item.customeFormFieldListJsonAnswer?.opportunityId
      && (this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin));

    return result;
  }

  public canGetProjectSuggestion(item: ClientCommitmentDTO): boolean {
    if (item.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled') {
      return false;
    }

    const result = item.dataSource !== 'clarity'
      && !this.isReadOnlyCommiment(item.accessLevel)
      && !item.customeFormFieldListJsonAnswer.hdnFulfillmentDetails
      && this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin);

    return result;
  }

  public canUnmapMappedProject(item: ClientCommitmentDTO): boolean {
    const result = item.canUnmapFulfillMent && item.dataSource !== 'clarity'
      && item.dataSource !== 'servicenow'
      && item.dataSource !== 'cims'
      && this.hasMapOrUnmapPermission
      && !this.isReadOnlyCommiment(item.accessLevel);

    return result;
  }

  public canUnmapMappedOpportunity(item: ClientCommitmentDTO): boolean {
    const result = item.canUnmapFulfillMent && item.dataSource !== 'ecrm'
      && (item.dataSource !== 'cims' || (item.dataSource === 'cims' && !item.customeFormFieldListJsonAnswer?.hasDefaultEcrmOpportunityId))
      && this.hasMapOrUnmapPermission
      && !this.isReadOnlyCommiment(item.accessLevel);

    return result;
  }

  public hasRolesCanDeleteCommitment(): boolean {
    const result = this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin);
    return result;
  }

  public hasRolesCanDelegateCommitment(): boolean {
    const result = this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin);
    return result;
  }

  public hasRolesCanTrackOrMarkAsRiskCommitment(): boolean {
    const result = this.hasRoleCanEditCommitment();
    return result;
  }

  public showTitleDataForMappedCommitmentDetails(item: ClientCommitmentDTO): string {
    let text = '';
    if (item.dataSource === 'ecrm') {
      text = 'opportunity';
    } else if (item.dataSource === 'clarity') {
      text = 'project';
    }
    return `The ${text} ${item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails.projectIdOrOpportunityId} is already mapped to an existing commitment '${item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails.commitmentTitleIntake}'`;
  }

  navigateToDataDict(url: string) {
    window.open(url, "_blank");
  }

  public plannedCommitteDeliveryDateGenerator(answerJsonObj: any): string {
    const plannedCommitteDeliveryDate = answerJsonObj['plannedCommitteDeliveryDate']?.toString();
    if (answerJsonObj['datasource'] === 'cims') {
      return this.convertToDate(plannedCommitteDeliveryDate);
    }

    if (answerJsonObj['datasource'] === 'servicenow' || answerJsonObj['datasource'] === 'manual') {
      const servicenowResult = this.convertToDate(plannedCommitteDeliveryDate);
      if (servicenowResult === '') {
        return this.convertToDate(answerJsonObj['plannedGoLiveDateRMIntake']?.toString());
      } else {
        return servicenowResult;
      }
    }

    const statusIntake = this.getStage(answerJsonObj['statusIntake']);
    const dateStatus = answerJsonObj['dateStatus'];
    const commitmentStatuses = [
      'Canceled',
      'In Progress',      
      'Completed',
      'Approved',
      'Delivered'
    ];
    if (dateStatus === 'Committed' && commitmentStatuses.includes(statusIntake)) {
      const result = this.convertToDate(plannedCommitteDeliveryDate);
      if (result === '') {
        return this.convertToDate(answerJsonObj['plannedGoLiveDateRMIntake']?.toString());
      } else {
        return result;
      }
    }

    const completedStatuses = [
      'Completed',
      'Canceled',  
      'Delivered'      
    ];
    if (statusIntake === 'On Hold'||
      ((!dateStatus || dateStatus === 'Planned' || dateStatus === '') &&
        completedStatuses.includes(statusIntake))) {
      return '';
    }

    const commitmentStatuses2 = [      
      'In Progress',
      'Approved'
    ];
    if ((answerJsonObj['datasource'] !== 'manual' && (statusIntake === 'Not Started' || statusIntake === 'In Planning')) 
        || ((!dateStatus || dateStatus === 'Planned' || dateStatus === '') 
        && commitmentStatuses2.includes(statusIntake))) {
      return 'To Be Scheduled';
    }
  }

  private convertToDate(value: string): string {
    const temp2 = moment(value).format('MM/DD/YYYY');
    if (!value || value === '' || value === 'Invalid date' || temp2 === 'Invalid date') {
      return '';
    }

    return temp2;
  }
  public GetStatusIntakeGenerator(answerJsonObj: any): string {
    const status = [
      ,'Delivered'
      ,'Completed'
      ,'Canceled'
      ,'Client - On Hold'
      ,'Fiserv - On Hold'
    ]
    const statusIntake = this.getStage(answerJsonObj['statusIntake']?.toString());
    const dateStatus = answerJsonObj['dateStatus']?.toString();
    const startDate = answerJsonObj['plannedCommittedStartDate']?.toString();    
    const datasource = answerJsonObj['datasource']?.toString();
    const currentDate = new Date();    
    if (status.includes(statusIntake)){
        return statusIntake;
    }
    else if (datasource.toLowerCase === 'clarity'){
      if (currentDate < startDate){
        return "Not Started";
      }
      else if (dateStatus === "Planned" && currentDate >= startDate)
        return "In Planning";
      else
        return "In Progress";      
    }
    else
      return this.getStage(statusIntake);    
  }

  public convertTextForStatus(value: string): string {
    if (value === 'Canceled') {
      return 'Canceled';
    }
  
    return value;
  }

  private formatDateTime(value, format, locale): any {
    const isValidDate = moment(value, 'MM/DD/YYYY', true).isValid();
    if (!value || value === 'To Be Scheduled' || !isValidDate) return value;

    const result = formatDate(value, format, locale);
    return result;
  }

  public transformDropdownValue(value: string): string {
    const result = value == 'other' ? 'Other (Please specify)' : value;
    return result;
  }

  public RemoveUnnecessaryCharacters(key: string, value: any): string {
    if (key !== 'productSIntake' || !value) return value;

    const result = this.filterLetters(value.toString(), this.unnecessaryProductNameCharacters);
    return result;
  }

  private filterLetters(str, lettersToRemove) {
    lettersToRemove.forEach((letter) => {
      str = str.replaceAll(letter, '');
    });

    return str;
  }

  public shouldDisableAllTrackCheckbox(): boolean {

    if (localStorage.getItem('commitments-intake-tab-name') === 'Presale'){
      return true;       
    }

    const isAllStrategic = this.data.every(x => x.isCustomClientCommitment === true);
    if (isAllStrategic) {
      return true;
    }

    const isAllEcrm = this.data.every(x => x.dataSource === 'ecrm');
    if (isAllEcrm) {
      const canTrackEcrm = this.permission.isCCTAdminWriteAccess(this.isPageTemplateAdmin);
      return !canTrackEcrm;
    }

    const hasNoMappedCommitment = this.data.some(x => !this.isMappedCommitment(x));
    if (!hasNoMappedCommitment) {
      return true;
    }

    const dataCanTracked = this.getPossibleTrackedData();
    if (!dataCanTracked || dataCanTracked.length === 0) {
      return true;
    }

    const isAllTracked = dataCanTracked.every(c => !c.hasTrackChanged && c.customeFormFieldListJsonAnswer.isTracked);
    if (isAllTracked) {
      return true;
    }

    const isAllDisabled = this.data.every(x => {
      const origin = this.originCommitments.find(o => o.id === x.id);
      const hasCancelledAndTracked = this.isCancelledCommitment(x) && origin.isTracked;
      if (hasCancelledAndTracked) {
        const shouldDisable = !this.isUserWriteAccess(x);
        return shouldDisable;
      }

      const isAllCheckboxDisabled = this.customFormFieldListJson.every(col => this.shouldDisableIsTrackCheckboxControl(x, col));
      return isAllCheckboxDisabled;
    });

    return isAllDisabled;
  }

  // Always disable IsTrack if commitment is mapped
  //   For Req1: Affect to Cancelled status and UN-TRACK only. Apply for all data source except eCRM 
  //      - Only user write access can un-track Cancelled record which already tracked. 
  //      - For another thing keep the existing one.
  //   For Req2: Apply for eCRM only
  //      - Now, only CCT Admin write access can track / untrack eCRM commitments no matter status
  public shouldDisableIsTrackCheckbox(item: ClientCommitmentDTO, col: any): boolean {

    if (item.dataSource !== 'ecrm') {
      if (this.isMappedCommitment(item)) {
        return true;
      }

      // For Req1
      const origin = this.originCommitments.find(o => o.id === item.id);
      if (this.isCancelledCommitment(item) && origin.isTracked) {
        // Only user write access can un-track canceled commitment
        const shouldDisable = !this.isUserWriteAccess(item);
        return shouldDisable;
      } else {
        const shouldDisable = this.shouldDisableIsTrackCheckboxControl(item, col);
        return shouldDisable;
      }
    } else {
      //  For Req2
      const origin = this.originCommitments.find(o => o.id === item.id);
      if (this.isCancelledCommitment(item) && !origin.isTracked) {
        return true;
      }
      else {
        const canTrackEcrm = this.permission.isCCTAdminWriteAccess(this.isPageTemplateAdmin);
        return !canTrackEcrm;
      }   
    }
  }

  // Disable IsRisk if commitment is mapped or any is ReadOnly or commitment is Cancelled
  public shouldDisableIsRiskCheckbox(item: ClientCommitmentDTO, col: any): boolean {
    // CCT Admin (with write access) user should have the ability to uncheck/check is risk for only ecrm project
    const isCCTAdminWriteAccess = this.permission.isCCTAdminWriteAccess(this.isPageTemplateAdmin);
    if (item.dataSource === 'ecrm' && isCCTAdminWriteAccess) {
      return false;
    }
    
    const isCancelledOrReadOnly = this.isCancelledOrReadOnlyCommitment(item, col);
    if (isCancelledOrReadOnly && (!item.customeFormFieldListJsonAnswer?.isTracked && item['statusA'] === 'untrack')) {
      return false;
    }
    else if (isCancelledOrReadOnly) {
      return true;
    }

    const isMapped = this.isMappedCommitment(item);
    if (isMapped) {
      return true;
    }

    return false;
  }

  
  public shouldDisableDuetoReadOnlyRoles(item: ClientCommitmentDTO, col: any): boolean {
    const isCancelledCommitment = this.isCancelledOrReadOnlyCommitment(item, col);
    if (isCancelledCommitment && (!item.customeFormFieldListJsonAnswer?.isTracked && item['statusA'] === 'untrack')) {
      return false;
    }
    else if (isCancelledCommitment) {
      return true;
    }

    const hasEditRole = this.hasRoleCanEditCommitment();
    if (!hasEditRole) {
      return true;
    }

    const isMapped = this.isMappedCommitment(item);
    if (isMapped) {
      return true;
    }

    return item.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse;
  }

  public shouldDisableIsTrackCheckboxControl(item: ClientCommitmentDTO, col: any): boolean {
    const shouldDisable = this.isCancelledOrReadOnlyCommitment(item, col) || this.isMappedCommitment(item);

    return shouldDisable;
  }

  public isCancelledOrReadOnlyCommitment(item: ClientCommitmentDTO, col: any): boolean {
    const isReadOnlyCommiment = this.isReadOnlyCommitment(item, col);
    const isCancelledCommitment = this.isCancelledCommitment(item);
    const shouldDisable = isReadOnlyCommiment || isCancelledCommitment || !this.isUserWriteAccess(item);

    return shouldDisable;
  }

  public isReadOnlyCommitment(item: ClientCommitmentDTO, col: any): boolean {
    const isReadOnly = (item.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse
      || item.customeFormFieldListJsonAnswer?.drmsReservedIsReadOnlyResponse == 'true'
      || item['accessLevel'] == 'Read'
      || item.isLinked
      || !col.isEditable);

    return isReadOnly;
  }

  public isMappedCommitment(item: ClientCommitmentDTO): boolean {
    const isMapped = item.customeFormFieldListJsonAnswer?.mappedCommitmentDetails ? true : false;
    return isMapped;
  }

  hasRoleCanEditCommitment(): boolean {
    const result = this.permission.hasWriteAccessOnCommitmentIntake(this.isPageTemplateAdmin);
    return result;
  }

  isCancelledCommitment(item: ClientCommitmentDTO): boolean {
    const result = item?.customeFormFieldListJsonAnswer?.statusIntake == 'Canceled';
    return result;
  }

  isUserWriteAccess(item: ClientCommitmentDTO): boolean {
    const isWriteAccess = item['accessLevel'] == 'Write';
    return isWriteAccess;
  }

  showEditUserAssignment(response : any) {
    if ((JSON.parse(response.answerJSON).isTracked != undefined && JSON.parse(response.answerJSON).isTracked == true)) {
      return true;
    }
    else {
      return false;
    }
  }

  SaveUsers() {

  }

  onSelectCEUsers(event) {

  }

  public changeColor(color: string): string {
    let result = '';
    switch (color) {
      case '#e53e3e':
        result = '#AA1717';
        break;

      default:
        result = color;
        break;
    }
    return result;
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('columnSettings');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }

  public scrollToTop(): any {
    var scrollTop = document.getElementById('scrollTop');
    if (scrollTop) {
      scrollTop.click();
    }
  }

  private getStage(stage: string): string {
    if (stage === "Approved")
      return "In Progress";
    else
      return stage;
  }
  oneViewClick(id: any): any {
    this.oneViewClicked.emit(id);
  }
}

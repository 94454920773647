import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IClients, IClient, IClientAddresses } from '../shared/client';
import { ISuggestions } from '../shared/suggestion';
import { Observable } from 'rxjs';
import { FileUploadHeader } from "../core/constants";
import { UtilService } from "./util.service";


@Injectable({
    providedIn: 'root'
})
export class ClientsService {

    private clientAPIURL = 'clients';
    private ExportclientAPIURL = 'clients/ExportClients';
    private managersAPIURL = 'managers';
    private addressAPIURL = 'addresses';

    constructor(private http: HttpClient, private utilService: UtilService) {

  }

  exportClients(queryParams) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.ExportclientAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });

    //return this.http.put<any>(`${this.ExportclientAPIURL}`, params,

    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }


    getClients(queryParams){
        let params = new HttpParams();

        for (let key of Object.keys(queryParams)) {
            let value = queryParams[key];
            params = params.append(key, value);
        }
        return this.http.get(this.clientAPIURL, { params: params, observe: 'response' });
  }

  getRefreshClients(queryParams) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (let key of Object.keys(queryParams)) {
      let value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.clientAPIURL, { headers:httpOptions.headers ,params: params, observe: 'response' });
  }


    getClientDetailsById(clientId: number, clientType: string) {
        return this.http.get<IClients>(this.clientAPIURL + "/" + clientId + "/" + clientType);
    }


    getClientContactsById(clientId: number) {
        let url = this.clientAPIURL + "/" + clientId + "/" + this.managersAPIURL;
        return this.http.get<ISuggestions>(url);
    }

    getClientList(clientName: string) {
      let url = this.clientAPIURL + "/clientlist?clientName=" + clientName;
      return this.http.get(url);
    }

    getRelationshipManagerSuggestions(entity: string, titleValue: string = "", paramName: string = 'title') {
      let options = { 'refresh': 'true' }
      const suggestionAPIURL = `${this.clientAPIURL}/${entity}/suggestions?${paramName}=${encodeURIComponent(titleValue)}`;
      return this.http.get<ISuggestions>(suggestionAPIURL, { headers: options });
  }

  getClientExecutiveUsersByClientId(clientId: number) {
    return this.http.get<any>(this.clientAPIURL + '/' + clientId + '/clientexecutiveusers');
  }

  getClientExecutiveUsersByOneViewGroupId(oneViewGroupId: number) {
    return this.http.get<any>(this.clientAPIURL + '/' + oneViewGroupId + '/clientexecutiveusersoneview');
  }

    createClient(data) {
      const formData = this.utilService.convertModelToFormData(data)
      const httpOptions = {
        headers: new HttpHeaders({
          [FileUploadHeader]: ""
        })
      };
      
      return this.http.post<IClient>(this.clientAPIURL, formData, httpOptions);
    }

    getClientDataById(id:number){
        return this.http.get<IClient>(`${this.clientAPIURL}/${id}`);
    }

    createClientAddress(data){
        return this.http.post<IClient>(this.addressAPIURL, data);
    }

    getClientAddress(id:number){
        return this.http.get<IClientAddresses[]>(`${this.clientAPIURL}/${id}/${this.addressAPIURL}`);
    }

    updateClient(clientId, data){
      const formData = this.utilService.convertModelToFormData(data)
      const httpOptions = {
        headers: new HttpHeaders({
          [FileUploadHeader]: ""
        })
      };

      return this.http.put<IClient>(`${this.clientAPIURL}/${clientId}`, formData, httpOptions);
    }

    updateAddress(addressId, data){
        return this.http.put<IClientAddresses>(`${this.addressAPIURL}/${addressId}`, data);
    }

    deleteClient(clientId: number): Observable<IClient> {
        return this.http.delete<IClient>(`${this.clientAPIURL}\\${clientId}`);
    }

    bulkRequest(formData) {
        let httpOptions = {
            headers: new HttpHeaders({
              [FileUploadHeader]: ""
            })
          };
          
        const fd = new FormData();
        for (let key of Object.keys(formData)) {
            let value = formData[key];
            fd.append(key, value);
        }

        return this.http.post(this.clientAPIURL + '/bulkclientrequest', fd, httpOptions);
    }
}

<div class="commitment-intake-container" *ngIf="isPdfExport">
  <div class="box-container statistics pl-0">
    <div class="separate-form-container exporting-container">
      <section class="summary-main-container">
        <div class="app-col-1">
          <div class="box-container statistics statistics-container pl-0">
            <h2>Commitment Statistics</h2>
            <div class="box-wrapper">
              <div class="swtich-container">
                <div class="total-count d-flex">
                  <div class="value">Total Commitment(s):</div>
                  <div class="count-badge">
                    {{ commitmentStats["Total"] }}
                  </div>
                </div>
              </div>
              <div
                class="scroll-horizontal non-sla d-flex flex-column">
                <ul class="box commitment-status">
                  <li class="box darkgrey">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status darkgrey"><i></i>Not Started</div>

                        <div class="status-count">
                          {{ this.commitmentStatsCount["Not Started"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="chart">
                            <div class="semi-donut margin">
                              <span class="semi-donut-percentage">{{ commitmentStats["Not Started"] }}%</span>
                              <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('Not Started')) + 'deg)'"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box grey">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status grey"><i></i>In Planning</div>

                        <div class="status-count">
                          {{ this.commitmentStatsCount["In Planning"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="chart">
                            <div class="semi-donut margin">
                              <span class="semi-donut-percentage">{{ commitmentStats["In Planning"] }}%</span>
                              <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('In Planning')) + 'deg)'"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box orange">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status orange"><i></i>In Progress</div>

                        <div class="status-count">
                          {{ this.commitmentStatsCount["In Progress"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="chart">
                            <div class="semi-donut margin">
                              <span class="semi-donut-percentage">{{ commitmentStats["In Progress"] }}%</span>
                              <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('In Progress')) + 'deg)'"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box fulfilled">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status blue"><i></i>Delivered</div>

                        <div class="status-count">
                          {{ this.commitmentStatsCount["Delivered"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="chart">
                            <div class="semi-donut margin">
                              <span class="semi-donut-percentage">{{ commitmentStats["Delivered"] }}%</span>
                              <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('Delivered')) + 'deg)'"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box fulfilled">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status blue"><i></i>Completed</div>
                        <div class="status-count">
                          {{ this.commitmentStatsCount["Completed"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="semi-donut margin">
                            <span class="semi-donut-percentage">{{ this.commitmentStats["Completed"] }}%</span>
                            <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('Completed')) + 'deg)'"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box on-hold">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status yellow"><i></i>On Hold</div>
                        <div class="status-count">
                          {{ this.commitmentStatsCount["On Hold"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="semi-donut margin">
                            <span class="semi-donut-percentage">{{ this.commitmentStats["On Hold"] }}%</span>
                            <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('On Hold')) + 'deg)'"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="box cancelled">
                    <div class="status-wrapper">
                      <div class="status-count-container">
                        <div class="status lightblue"><i></i>Canceled</div>
                        <div class="status-count">
                          {{ this.commitmentStatsCount["Canceled"] }}
                        </div>
                      </div>
                      <div class="chart-container">
                        <div class="chart">
                          <div class="semi-donut margin">
                            <span class="semi-donut-percentage">{{ this.commitmentStats["Canceled"] }}%</span>
                            <i [style.transform]="'rotate(' + (calculateSemiDonutRotation('Canceled')) + 'deg)'"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="legend-wrapper">
                <span class="legend-text">Overall Progress</span>
              </div>
            </div>
          </div>

          <div class="box-container delivery-container pl-0 mt-19">
            <div class="commitments-calendar">
              <h2>Delivery Calendar</h2>
              <div
                class="box-wrapper"
                *ngIf="
                  commitmentCalendarNumbers.length == 0 &&
                  commitmentCalendar &&
                  !dataCommitmentCalendarError
                "
              >
                <div class="no-data-message-container">
                  <div class="product-wrapper no-data-message">
                    <h3>No Data Available</h3>
                  </div>
                </div>
              </div>
              <div class="box-wrapper" *ngIf="dataCommitmentCalendarError">
                <div class="no-data-message-container">
                  <div class="product-wrapper no-data-message">
                    <h3>There was a problem. Please try again later.</h3>
                  </div>
                </div>
              </div>
              <div
                class="box-wrapper"
                [ngClass]="
                  (commitmentCalendarNumbers.length != 0 || !commitmentCalendar) &&
                  !dataCommitmentCalendarError
                    ? 'd-flex'
                    : 'd-none'
                "
              >
                <div class="filter-area">
                  <div class="swtich-container mb-0">
                    <div class="switch-wrapper">
                      <div
                        class="tab-switch"
                        id="month"
                        [ngClass]="
                          typeOfCommitmentCalander == 'month' ? 'active' : ''
                        "
                        (click)="loadCommitmentCalendar('month')"
                      >
                        Monthly
                      </div>
                      <div
                        class="tab-switch"
                        id="qtr"
                        [ngClass]="
                          typeOfCommitmentCalander == 'qtr' ? 'active' : ''
                        "
                        (click)="loadCommitmentCalendar('qtr')"
                      >
                        Quarterly
                      </div>
                      <div
                        class="tab-switch"
                        id="year"
                        [ngClass]="
                          typeOfCommitmentCalander == 'year' ? 'active' : ''
                        "
                        (click)="loadCommitmentCalendar('year')"
                      >
                        Yearly
                      </div>
                    </div>
                  </div>
                </div>

                <div class="chart" *ngIf="!commitmentCalendar">
                  <div
                    role="status"
                    aria-label="Please wait, do not refresh the page"
                    class="ccdrms-loader"
                    style="position: relative; margin-top: 20%"
                  ></div>
                </div>
                <div class="chart" [ngClass]="commitmentCalendar ? 'd-flex' : 'd-none'">
                  <figure class="deliveryCalendarData">
                    <svg id="deliveryCalendarChart"></svg>
                    <caption>
                      <ul id="legend"></ul>
                    </caption>
                  </figure>
                </div>
              </div>
            </div>
          </div>
          
          <div class="box-container commitments-table commitments-container">
            <div class="commitments-tabs-wrapper">
              <ul class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    role="tab"
                    aria-selected="false"
                    href="#tab13"
                    data-toggle="tab"
                    >Project Commitments
                    <span class="total-count">
                      <span class="count-badge">{{ projectTotalCount|| 0 }}</span>
                    </span>
                  </a>
                </li>
              </ul>
              
              <!-- Tab panes -->
              <div>
                <div
                  class="tab-pane"
                >
                  <div class="box-container commitments-table mt-0">
                    <div class="box-wrapper table-container">
                      <div class="table-section cct-table s-overflow-auto">
                        <div
                          class="no-data-message-container p-0 pt-2"
                          *ngIf="projectTotalCount == 0 && !commitmentsError"
                        >
                          <div class="product-wrapper no-data-message">
                            <h3>No Data Available</h3>
                          </div>
                        </div>
                        <div
                          class="no-data-message-container p-0 pt-2"
                          *ngIf="commitmentsError"
                        >
                          <div class="product-wrapper no-data-message">
                            <h3>
                              There was a problem. Please try again later.
                            </h3>
                          </div>
                        </div>
                        <app-data-table-updated
                          *ngIf="projectTotalCount > 0"
                          [headerColumns]="headerColumns"
                          [keys]="keys"
                          [rows]="commitmentAllRows"
                          [showActionButton]="false"
                          [enableSorting]="false"
                          [extraRowFieldName]="'commitmentNote'"
                        ></app-data-table-updated>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-container commitments-table commitments-container">
            <div class="commitments-tabs-wrapper">
              <ul class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    role="tab"
                    aria-selected="false"
                    href="#tab14"
                    data-toggle="tab"
                    >Non Project Commitments
                    <span class="total-count">
                      <span class="count-badge">{{ nonProjectTotalCount || 0 }}</span>
                    </span>
                  </a>
                </li>
              </ul>
              
              <!-- Tab panes -->
              <div>
                <div
                  class="tab-pane"
                >
                  <div class="box-container commitments-table mt-0">
                    <div class="box-wrapper table-container">
                      <div class="table-section cct-table s-overflow-auto">
                        <div
                          class="no-data-message-container p-0 pt-2"
                          *ngIf="nonProjectTotalCount == 0 && !commitmentsError"
                        >
                          <div class="product-wrapper no-data-message">
                            <h3>No Data Available</h3>
                          </div>
                        </div>
                        <div
                          class="no-data-message-container p-0 pt-2"
                          *ngIf="commitmentsError"
                        >
                          <div class="product-wrapper no-data-message">
                            <h3>
                              There was a problem. Please try again later.
                            </h3>
                          </div>
                        </div>
                        <app-data-table-updated
                          *ngIf="nonProjectTotalCount > 0"
                          [headerColumns]="headerColumns"
                          [keys]="keys"
                          [rows]="commitmentManualRows"
                          [showActionButton]="false"
                          [enableSorting]="false"
                          [extraRowFieldName]="'commitmentNote'"
                        ></app-data-table-updated>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-container product-details products-container pl-0 mt-19">
            <div class="product-container">
              <h2>Commitments by Products</h2>
            </div>
            <div class="box-wrapper">
              <ul
                class="product-list"
                [ngClass]="
                  commitmentProducts && commitmentProducts.length > 7
                    ? 'border-bottom'
                    : ''
                "
              >
                <li *ngFor="let k of pageProducts">
                  <div class="product-wrapper">
                    <h3 title="{{ k.productName }}">{{ k.productName }}</h3>
                    <p title="{{ k.productDescription }}">
                      {{ k.productDescription }}
                    </p>
                  </div>
                  <div class="two-col">
                    <div class="name">
                      <span class="badge">{{ k.totalCommitments }}</span>
                    </div>
                  </div>
                </li>

                <!-- No data available -->
                <li
                  *ngIf="commitmentProducts && commitmentProducts.length == 0"
                >
                  <div class="product-wrapper no-data-message">
                    <h3>No Data Available</h3>
                  </div>
                </li>
                <li *ngIf="cBProductsError">
                  <div class="product-wrapper no-data-message">
                    <h3>There was a problem.<br />Please try again later.</h3>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          
          <div class="box-container client-note-container pl-0">
            <div style="margin-top: 10px;">
              <h2>Notes</h2>
            </div>
              <pre style="
                white-space: pre-wrap;
                word-wrap: break-word;
                color: #212529;
                font-weight: 400;
                font: normal normal normal 14px/14px Segoe UI;">{{note}}</pre>
          </div>

          <div class="box-container contacts-container pl-0">
            <div class="contacts">
              <h2>Contacts</h2>
              <div class="box-wrapper">
                <app-commitment-dashboard-contacts [summary]="summary" [isExporting]="true"></app-commitment-dashboard-contacts>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- Basic Info Page Ends  -->
</div>

export const environment = {
    production: true,
    env: 'prod',
    pageSize: 10,
    pdfSkipFormId: "1287,1105,1086,604",
    clientDashboardUserManualId: 65,
    clientDashboardFAQId: 66,
    clientCommitmentPageTemplateId: 8,
    isCCT: true,
    apibaseurl: "https://commitments.fiservapps.com/api/",    
    summaryReportUrl: 'https://app.powerbi.com/groups/b60b3d12-b8f8-496f-b3cd-cce5a043ff82/reports/6ea9a78e-fd80-4022-99da-5f10f5763c57?ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&experience=power-bi&subfolderId=11413',                        
    clientDashboardFAQs: 'https://cct-prod-agebfqhbf9fwd5b7.a02.azurefd.net/container-prod-cct/doc/client-dashboard-faqs-062320230615115225.pdf',
    cctUserManual: 'https://cct-prod-agebfqhbf9fwd5b7.a02.azurefd.net/container-prod-cct/doc/Client Commitment Tracker User Manual20230609164601.pdf',
    cctUserGuide: 'https://cct-prod-agebfqhbf9fwd5b7.a02.azurefd.net/container-prod-cct/doc/Client Commitment Tracker Internal User Guide.Sept 2024 update.pdf',
    excludedDatasources: "ecrm",
    drmsPowerBIURL: 'https://app.powerbi.com/reportEmbed?reportId=691dbe76-f72b-42d9-b042-6de0f9964d52&autoAuth=true&ctid=11873a1f-4c8d-450d-8dfb-e37a2e2557f8&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZC1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    appInsights: {
        instrumentationKey: "5f3e0aca-ede9-4432-91f9-51208a98fba1"
    },
    webApiConfig: {
        webApiEndpoint: 'https://commitments.fiservapps.com/api/',
    },
    pingIdOAuthSettings: {
        authorizationUrl: 'https://fdc-fedsso.firstdata.com/as/authorization.oauth2',
        tokenUri: 'https://fdc-fedsso.firstdata.com/as/token.oauth2',
        redirectUri: 'https://commitments.fiservapps.com',
        clientId: 'APM0009340CCT',
        clientSecret: 'CslrOL5xiSmF2raNlJN9IvppllE5w6NAuu8OuRxvA60U5QMgpn7Dgp5gF2FPXIRj',  
        grantType: 'authorization_code',
        logoutUrl: 'https://fdc-fedsso.firstdata.com/idp/startSLO.ping'
    }
};
